<template>
  <a-cascader
    v-model:value="value"
    :options="cityoptions"
    :load-data="loadData"
    placeholder="请选择地区"
    change-on-select
    @change="cahngeVal"
  />
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import http from '@/util/http.js'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'cascader_s',
  props: {
    modelValue: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    let value = ref(props.modelValue)
    const options = ref([])
    // 存储数据
    let cityoptions = ref([])
    const store = useStore()
    // 省、市联机选择器数据获取
    let opcity = (e) => {
      http
        .post({
          serviceCode: 'getCityList',
          param: {
            cantCode: e,
          },
        })
        .then((res) => {
          if (e == 'CN') {
            res.rows.map((it, index) => {
              if (
                (['ah','ahkx','ahkx1'].some(item=>{return item==store.state.urlAh })) &&
                it.cantCode == '340000'
              ) {
                cityoptions.value[0] = {
                  label: it.cantName,
                  level: 0,
                  isLeaf: false,
                  value: it.cantCode,
                }
              }
              if (store.state.urlAh == 'lc') {
                if(store.state.CjhType==false){
                  cityoptions.value[0] = {
                    label: '山东省',
                    level: 0,
                    isLeaf: false,
                    value: '370000',
                  }
                }else{
                  cityoptions.value[0] = {
                    label: '聊城市',
                    level: 0,
                    isLeaf: false,
                    value: '371500000000',
                  }
                }
              }
              if( store.state.urlAh == 'yt' ){
                cityoptions.value[0] = {
                  label: '山东省',
                  level: 0,
                  isLeaf: false,
                  value: '370000',
                }
                setTimeout(()=>{
                  value.value=['370000'];
                },500)
              }
              if(['zyx','mz','ahkx','ahkx1'].some(item=>{return item===store.state.urlAh})){
                cityoptions.value[0] = {
                  label: '安徽省',
                  level: 0,
                  isLeaf: false,
                  value: '340000',
                }
                setTimeout(()=>{
                  value.value=['340000'];
                },500)
              }
              if (

                store.state.urlAh == 'hz' ||
                store.state.urlAh == 'hz1'
              ) {
                cityoptions.value[index] = {
                  label: it.cantName,
                  level: 0,
                  isLeaf: false,
                  value: it.cantCode,
                }
                // cityoptions.value[0] = {
                //   label: '菏泽市',
                //   level:0,
                //   isLeaf: false,
                //   value: '371700000000',
                // }
              }
              if (store.state.urlAh == 'ta') {
                cityoptions.value[0] = {
                  label: '泰安市',
                  level: 0,
                  isLeaf: false,
                  value: '370900000000',
                }
              }
            })
          } else if (cityoptions.value[xiabiao.value].level == 0) {
            res.rows.map((it) => {
              cityoptions.value[xiabiao.value].items = {
                label: it.cantName,
                value: it.cantCode,
              }
            })
          }
        })
    }
    let xiabiao = ref()

    opcity('CN')
    let centshow = () => {
      value.value = ['']
    }

    const loadData = (selectedOptions) => {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      if (selectedOptions.length == 1) {
        http
          .post({
            serviceCode: 'getCityList',
            param: {
              cantCode: selectedOptions[0].value,
            },
          })
          .then((res) => {
            let arr = []
            targetOption.loading = false
            res.rows.map((it) => {
              arr.push({
                label: it.cantName,
                value: it.cantCode,
                level: 1,
                isLeaf: false,
              })
            })
            targetOption.children = arr
          })
        options.value = [...options.value]
      } else if (selectedOptions.length == 2) {
        http
          .post({
            serviceCode: 'getCityList',
            param: {
              cantCode: selectedOptions[1].value,
            },
          })
          .then((res) => {
            let arr = []
            targetOption.loading = false
            res.rows.map((it) => {
              arr.push({
                label: it.cantName,
                value: it.cantCode,
                level: 1,
              })
            })
            targetOption.children = arr
          })
        options.value = [...options.value]
      }
    }
    let cahngeVal = (e, i) => {
      let val = {}
      if (i == undefined) {
        val.provinceCode = ''
        val.provinceName = ''
        val.cityCode = ''
        val.cityName = ''
        val.countyCode = ''
        val.countyName = ''
        return
      }
      if (i.length == 1) {
        val.provinceCode = i[0].value
        val.provinceName = i[0].label
      } else if (i.length == 2) {
        val.provinceCode = i[0].value
        val.provinceName = i[0].label
        val.cityCode = i[1].value
        val.cityName = i[1].label
      } else if (i.length == 3) {
        val.provinceCode = i[0].value
        val.provinceName = i[0].label
        val.cityCode = i[1].value
        val.cityName = i[1].label
        val.countyCode = i[2].value
        val.countyName = i[2].label
      }
      if (['lc', 'ta'].some(v => v === store.state.urlAh)) {
        val.provinceCode = '370000'
        val.provinceName = '山东省'
        val.cityCode = i[0]?.value
        val.cityName = i[0]?.label
        val.countyCode = i[1]?.value
        val.countyName = i[1]?.label
      }
      emit('update:modelValue', val)
    }
    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue instanceof Array) {
          value.value = [...props.modelValue]
        }
      }
    )
    return {
      value,
      options,
      loadData,
      opcity,
      cityoptions,
      cahngeVal,
      centshow,
    }
  },
})
</script>
