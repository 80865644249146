const utils={
  // 获取登录平台名字
  loginName(){
    const href=window.location.href;//获取当前链接
    let name='';
    if(href.indexOf('zyxahkx.')!==-1){ //安徽科协
      name='安徽科协管理平台';
    }else if(href.indexOf('ta.')!==-1){ //泰安
      name='泰安市管理平台';
    }else if(href.indexOf('lc.')!==-1){ //聊城
      name='聊城市管理平台';
    }else if(href.indexOf('zyx.')!==-1){ //安徽众益学
      name='众益学市管理平台';
    }else if(href.indexOf('hzcjh.')!==-1){ //菏泽促进会
      name='菏泽市继续教育管理平台';
    }else if(href.indexOf('hz.')!==-1){ //菏泽
      name='菏泽市管理平台';
    }else if(href.indexOf('mz.')!==-1){ //马资
      name='马鞍山市管理平台';
    }else if(href.indexOf('yt.')!==-1){ //马资
      name='烟台市管理平台';
    }else if(href.indexOf('gs.')!==-1){ //甘肃
      name='甘肃政务中心';
    }
    // name='泰安市管理平台'
    return name;
  },
};
export  default utils;