<template>
  <div class="ZjVerification">
    <!-- :footer="null"  取消组件自带的确定框 -->
    <a-modal v-model:visible="visible" @cancel="closevisible" :maskClosable="maskClosable" :closable="closable" :keyboard="keyboard" :getContainer="getContainer" @ok="handleOk"
             cancelText="取消" :width="width" :footer="null">
      <template #title>
        <div class="titlebox">{{ title }}</div>
      </template>
      <div class="ttbox_n">
        <div>
          <slot name="neirong"></slot>
        </div>
        <div>
        </div>
      </div>
      <template #footer v-if="slots.neibutton">
        <slot name="neibutton"></slot>
      </template>
      <template #closeIcon>
        <div class="outlined">
          <close-outlined />
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent, reactive, toRefs, useSlots, watch} from 'vue';
import { CloseOutlined} from '@ant-design/icons-vue';
export default defineComponent({
  name: 'ZjVerification',
  props: {
    Title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ()=>{
        return '40%'
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    keyboard: {
      type: Boolean,
      default: false
    },
    maskClosable: {
      type: Boolean,
      default: true
    },
    closable:{
      type:Boolean,
      default:true
    },
  },
  components:{
    CloseOutlined
  },
  setup(props, {emit}) {
    const slots = useSlots();
    let data = reactive({
      visible: props.show,
      maskClosable: props.maskClosable,
      closable: props.closable,
      width:props.width,
      showModal: () => {
        data.visible = true;
      },
      handleOk: () => {
        data.visible = false;
      },
      //关闭弹框
      closevisible() {

        emit('update:show', false)
        emit('cancel', false)
      },
      title: props.Title
    })
    watch(() => props.show, (e) => {
      data.visible = e;
    })
    watch(() => props.Title, (e) => {
      data.title = e;
    })
    //挂在到app
    const getContainer = () => document.querySelector("#app");
    return {
      getContainer,
      slots,
      ...toRefs(data)
    };
  },

});
</script>

<style scoped>
.ant-modal-content /deep/ .ant-modal-body {
  padding: 0 10px !important;
}

.titlebox {
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 800;
  text-align: center;
  margin: 10px;
}

/* .ttbox_n {
 margin-top: 10px;
} */
.outlined {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  /* background: red; */
  margin: auto;
  margin-top: 8px;
}

</style>