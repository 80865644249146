import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: '首页',
    component:  () => import('@/views/index.vue'),
    redirect:'/Index',
    meta: { title: "首页" },
    children: [//嵌套一级子路由
      {
        path: "/Index",//子路由前不需要加斜杠 /
        name: "index_index",
        component: () => import('@/views/layout/index.vue'),
        props: true,
        meta: { title: "首页" },
      },
      {
        path: "/Study",//子路由前不需要加斜杠 /
        name: "Study_index",
        component: () => import('@/views/Study/index.vue'),
        props: true,
        meta: { title: "研修班" },
      },
      {
        path: "/SurroundingCity",//子路由前不需要加斜杠 /
        name: "SurroundingCity_index",
        component: () => import('@/views/SurroundingCity/index.vue'),
        props: true,
        meta: {
          title: "周边城市专技平台",
          keepAlive:true
        },
      },
      {
        path: "/LiveBroadcast",//子路由前不需要加斜杠 /
        name: "LiveBroadcast_index",
        component: () => import('@/views/LiveBroadcast/index.vue'),
        props: true,
        meta: { title: "直播中心" },
      },
      {
        path: "Notice",//子路由前不需要加斜杠 /
        name: "Notice_index",
        component: () => import('@/views/Notice/index.vue'),
        props: true,
        meta: { title: "公需课选课" },
      },
      {
        path: "90ClassHoures",//子路由前不需要加斜杠 /
        name: "90ClassHoures_index",
        component: () => import('@/views/90ClassHoures/index.vue'),
        props: true,
        meta: { title: "90学时课程包" },
      },
      {
        path: "collective",//子路由前不需要加斜杠 /
        name: "collective_index",
        component: () => import('@/views/collective/index.vue'),
        props: true,
        meta: { title: "集体报名" },
      },
      {
        path: "Professional",//子路由前不需要加斜杠 /
        name: "Professional_index",
        component: () => import('@/views/Professional/index.vue'),
        props: true,
        meta: { title: "专业课选课" },
      },
      {
        path: "special",//子路由前不需要加斜杠 /
        name: "special_index",
        component: () => import('@/views/Professional/special.vue'),
        props: true,
        meta: { title: "专题详情" },
      },
      {
        path: "ChangingCareers",//子路由前不需要加斜杠 /
        name: "ChangingCareers_index",
        component: () => import('@/views/ChangingCareers/index.vue'),
        props: true,
        meta: { title: "转岗培训" },
      },
      {
        path: "Announcement",//子路由前不需要加斜杠 /
        name: "Announcement_index",
        component: () => import('@/views/Announcement/index.vue'),
        props: true,
        meta: { title: "通知公告" },
      },
      {
        path: "village",//子路由前不需要加斜杠 /
        name: "village_index",
        component: () => import('@/views/village/index.vue'),
        props: true,
        meta: { title: "乡村振兴选课" },
      },
      {
        path: "EngineeringTechnology",//子路由前不需要加斜杠 /
        name: "EngineeringTechnology_index",
        component: () => import('@/views/EngineeringTechnology/index.vue'),
        props: true,
        meta: { title: "工程技术" },
      },
      {
        path: "forestry",//子路由前不需要加斜杠 /
        name: "forestry_index",
        component: () => import('@/views/forestry/index.vue'),
        props: true,
        meta: { title: "林业工程" },
      },
      {
        path: "land",//子路由前不需要加斜杠 /
        name: "land_index",
        component: () => import('@/views/land/index.vue'),
        props: true,
        meta: { title: "地勘土地测绘规划工程" },
      },
      {
        path: "Details/:id",//子路由前不需要加斜杠 /
        name: "Details_index",
        component: () => import('@/views/Details/index.vue'),
        props: true,
        meta: { title: "信息详情" },
      },
      {
        path: "DetailScreening",//子路由前不需要加斜杠 /
        name: "DetailScreening_index",
        component: () => import('@/views/Details/detailScreening.vue'),
        props: true,
        meta: { title: "信息详情" },
      },
      {
        path: "helpdetail/:id",//子路由前不需要加斜杠 /
        name: "helpdetail_index",
        component: () => import('@/views/Details/helpdetail.vue'),
        props: true,
        meta: { title: "帮助详情" },
      },
      {
        path: "VideoDetails",//子路由前不需要加斜杠 /
        name: "VideoDetails_index",
        component: () => import('@/views/VideoDetails/index.vue'),
        props: true,
        meta: { title: "课程详情" },
      },
      {
        path: "VideoDetails1",//子路由前不需要加斜杠 /
        name: "VideoDetails_index1",
        component: () => import('@/views/VideoDetails/index1.vue'),
        props: true,
        meta: { title: "课程详情" },
      },
      {
        path: "Certificate",//子路由前不需要加斜杠 /
        name: "Certificate_index",
        component: () => import('@/views/Certificate/index.vue'),
        props: true,
        meta: { title: "证书查询" },
      },
      {
        path: "Policyadvice",//子路由前不需要加斜杠 /
        name: "Policyadvice_index",
        component: () => import('@/views/Policyadvice/index.vue'),
        props: true,
        meta: { title: "职称政策" },
      },
      {
        path: "ActivityDisplay",//子路由前不需要加斜杠 /
        name: "activityDisplay_index",
        component: () => import('@/views/ActivityDisplay/index.vue'),
        props: true,
        meta: { title: "活动展示" },
      },
      {
        path: "Registration",//子路由前不需要加斜杠 /
        name: "Registration_index",
        component: () => import('@/views/Registration/index.vue'),
        props: true,
        meta: { title: "注册" },
      },
      {
        path: "platform",//子路由前不需要加斜杠 /
        name: "platform_index",
        component: () => import('@/views/platform/index.vue'),
        props: true,
        meta: { title: "周边城市平台" },
      },
      {
        path: "Personal",//子路由前不需要加斜杠 /
        name: "Personal_index",
        component: () => import('@/views/Personal/index.vue'),
        redirect:'/Personal/studytime',
        props: true,
        meta: { title: "个人中心" },
        children: [
          {
            path: "studytime",//子路由前不需要加斜杠 /
            name: "studytime",
            component: () => import('@/views/Personal/component/studytime.vue'),
            props: true,
            meta: { title: "我的学时" },
          },
          {
            path: "exercises",//子路由前不需要加斜杠 /
            name: "exercises",
            component: () => import('@/views/Personal/component/exercises.vue'),
            props: true,
            meta: { title: "考前练习" },
          },
          {
            path: "Classification",//子路由前不需要加斜杠 /
            name: "Classification",
            component: () => import('@/views/Personal/component/Classification.vue'),
            props: true,
            meta: { title: "我的课程" },
          },
          {
            path: "Myorder",//子路由前不需要加斜杠 /
            name: "Myorder",
            component: () => import('@/views/Personal/component/Myorder.vue'),
            props: true,
            meta: { title: "我的订单" },
          },
          {
            path: "Examination",//子路由前不需要加斜杠 /
            name: "Examination",
            component: () => import('@/views/Personal/component/Examination.vue'),
            props: true,
            meta: { title: "考试中心" },
          },
          {
            path: "ExamDetails",//子路由前不需要加斜杠 /
            name: "ExamDetails",
            component: () => import('@/views/Personal/component/ExamDetails.vue'),
            props: true,
            meta: { title: "考试中心" }, // 考试详情页
          },
          {
            path: "Invoice",//子路由前不需要加斜杠 /
            name: "Invoice",
            component: () => import('@/views/Personal/component/Invoice.vue'),
            props: true,
            meta: { title: "发票中心" },
          },
          {
            path: "information",//子路由前不需要加斜杠 /
            name: "information",
            component: () => import('@/views/Personal/component/information.vue'),
            props: true,
            meta: { title: "个人信息" },
          },
          {
            path: "Certificate",//子路由前不需要加斜杠 /
            name: "Certificate",
            component: () => import('@/views/Personal/component/Certificate.vue'),
            props: true,
            meta: { title: "打印证书" },
          },
          {
            path: "Announcement1",//子路由前不需要加斜杠 /
            name: "Announcement_index1",
            component: () => import('@/views/Personal/component/Announcement.vue'),
            props: true,
            meta: { title: "通知公告" },
          },
          {
            path: "Detailstail/:id",//子路由前不需要加斜杠 /
            name: "Detailstail_index",
            component: () => import('@/views/Personal/component/Details.vue'),
            props: true,
            meta: { title: "通知公告详情" },
          },
          {
            path: "Mywork",//子路由前不需要加斜杠 /
            name: "Mywork_index",
            component: () => import('@/views/Personal/component/Mywork.vue'),
            props: true,
            meta: { title: "我的作品" },
          },
          {
            path: "Coursemanagement",//子路由前不需要加斜杠 /
            name: "Coursemanagement_index",
            component: () => import('@/views/Personal/component/Coursemanagement.vue'),
            props: true,
            meta: { title: "课程管理" },
          },
        ]
      },
      {
        path: "Helpcenter",//子路由前不需要加斜杠 /
        name: "Helpcenter_index",
        component: () => import('@/views/Helpcenter/index.vue'),
        props: true,
        redirect:'/Helpcenter/TrainingInstructions?itemValue=1',
        meta: { title: "帮助中心" },
        children: [
          {
            path: "OperationProcess",//子路由前不需要加斜杠 /
            name: "OperationProcess",
            component: () => import('@/views/Helpcenter/Process/OperationProcess.vue'),
            props: true,
            meta: {
              title: "培训流程",
              itemValue:'2'
            },
          },
          {
            path: "TrainingInstructions",//子路由前不需要加斜杠 /
            name: "TrainingInstructions",
            component: () => import('@/views/Helpcenter/Process/TrainingInstructions.vue'),
            props: true,
            meta: { title: "培训须知",
              itemValue:'1'
            },
          },
          {
            path: "Process",//子路由前不需要加斜杠 /
            name: "Process_index",
            component: () => import('@/views/Helpcenter/Process/index.vue'),
            props: true,
            meta: { title: "帮助详情",              itemValue:'4'
            },
          },
          {
            path: "demonstration",//子路由前不需要加斜杠 /
            name: "demonstration_index",
            component: () => import('@/views/Helpcenter/Process/demonstration.vue'),
            props: true,
            meta: { title: "操作演示",              itemValue:'3'
            },
          },
        ]
      },
      {
        path: "OrderInformation",//子路由前不需要加斜杠 /
        name: "orderInformation_index",
        component: () => import('@/views/OrderInformation/orderInformation.vue'),
        props: true,
        meta: { title: "订单信息",              itemValue:'3'
        },
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: "Exception_index",
    component: () => import(/* webpackChunkName: "exception" */ '@/views/exception/404.vue'),
  },
  {
    path:'/reminder',
    name:'兼容提示',
    component:  () => import('@/views/reminder/index.vue'),
    meta: { title: "兼容提示" },
  }
]
// 不带＃号
// history: createWebHistory(),
// history: createWebHashHistory(),
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
