import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import componentPlugin from '@/components'
import Antd from 'ant-design-vue'
import { Modal } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'
import '@/assets/css/iconfont.css'
// 引入字体包
import './assets/font/font.css'
// 导入适配方案
import 'lib-flexible/flexible.js'
// 打印功能
import print from 'vue3-print-nb'

import { message } from 'ant-design-vue';
import publicFn from "@/util/publicFn";
import './util/permission.js'

message.config({
    top: `100px`,
    duration: 1,
    maxCount: 1,
    prefixCls: 'mymessage ant-message',
});

router.onError((error) => {
    const jsPattern = /Loading chunk (\S)+ failed/g
    const cssPattern = /Loading CSS chunk (\S)+ failed/g
    const isChunkLoadFailed = error.message.match(jsPattern || cssPattern)
    const targetPath = router.history.pending.fullPath
    if (isChunkLoadFailed) {
        localStorage.setItem('targetPath', targetPath)
        router.replace(targetPath)
    }
})
//2.路由发生变化修改页面title
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.documentElement.scrollTop = 0;
        if(window.location.href.indexOf('yt.')!==-1){
            document.title = '烟台市人力资源发展促进会';
        }else if(window.location.href.indexOf('ah.peixun')!==-1){
            document.title = '安徽专技网-学习二十大精神，建设美好安徽';
        }else if(window.location.href.indexOf('ahkx.')!==-1){
            document.title = '安徽科学传播继续教育网';
        }else if(window.location.href.indexOf('hz.tyjrpx')!==-1){
            document.title = '菏泽专技网';
        }else if(window.location.href.indexOf('ta.')!==-1){
            document.title = '泰安专业技术人员继续教育培训网';
        }else if(window.location.href.indexOf('lc')!==-1){
            document.title = '聊城市乡村实用人才培训学院';
        }else if(window.location.href.indexOf('jsxy.')!==-1){
            document.title =  '菏泽市人力资源发展促进会';
        }else if(window.location.href.indexOf('mz.')!==-1){
            document.title =  '马鞍山自然资源与规划局（林业局）';
        }else{
            document.title = to.meta.title;
        }
    }
    next();
});

let initAPP = async ()=>{
    let href=window.location.href;
   const sdjypxShow=window.location.href.indexOf('sdjypx')!==-1?true:false;
    // let href='https://ah.peixun.city/Index';
       store.commit('setCjhType',true);
         store.commit('setUrlAh',false)
    if(href.indexOf('ah.tyjrpx')!==-1||href.indexOf('ah.sdjypx')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }else{
            await publicFn.getAhUserInfo();
        }
        // 安徽用户是否报名
        if(localStorage.getItem('userinfo')){
            let SignUp= await publicFn.getAHUserSignUp();
            if(SignUp&&SignUp.isEnroll==0){
                Modal.confirm({
                    title: '提示',
                    content: () =>
                        <div v-html={SignUp.title}/>
                    ,
                    okText: '立即报名',
                    cancelText: '暂不报名',
                    onOk() {
                        window.open('http://117.68.7.48:8081/center/#/train')
                    },
                },);
            }
        }
        store.commit('setUrlAh','ah');
            store.commit('setHzType',false);
    }
    if(href.indexOf('ah.peixun')!==-1||href.indexOf('zyx.sdjypx')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }else{
            await publicFn.getAhUserInfo();
        }
        store.commit('setUrlAh','zyx');
        store.commit('setHzType',false);
        // 安徽用户是否报名
        // if(localStorage.getItem('userinfo')){
        //     let SignUp= await publicFn.getAHUserSignUp();
        //     if(SignUp&&SignUp.isEnroll==0){
        //         Modal.confirm({
        //             title: '提示',
        //             content: () =>
        //                 <div v-html={SignUp.title}/>
        //             ,
        //             okText: '立即报名',
        //             cancelText: '暂不报名',
        //             // cancelButtonProps:{
        //             //     style: { display: 'none' }, //去掉暂不报名按钮
        //             //     disabled:true,
        //             // },
        //             onOk() {
        //                 window.open('http://117.68.7.48:8081/center/#/train')
        //             },
        //         },);
        //     }
        // }
    }
    if(href.indexOf('ahkx.')!==-1||href.indexOf('zyxahkx.')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }
        if(href.indexOf('peixun.')!==-1){
            store.commit('setUrlAh','ahkx1')
          store.commit('setUrlAhVersion','ahkx1')
        }else{
            store.commit('setUrlAh','ahkx')
        }
        store.commit('setHzType',false);
    }
    if(href.indexOf('hz.')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }else{
            await publicFn.getHzUserInfo();
        }
        store.commit('setUrlAh','hz')
        store.commit('setHzType',true)
    }
    if(href.indexOf('lc.')!==-1){
        store.commit('setUrlAh','lc');
        store.commit('setHzType',false);
        store.commit('setCjhType',true);
    }
    if(href.indexOf('ta.')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }
        store.commit('setUrlAh','ta');
        store.commit('setHzType',false);
    }
    if(href.indexOf('yt.')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }
        store.commit('setUrlAh','yt');
        store.commit('setHzType',false);
    }

    if(href.indexOf('jsxy.')!==-1){// 菏泽促进会
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }else{
            await publicFn.getHzUserInfo();
        }
        store.commit('setUrlAh','hz1')
        store.commit('setHzType',true)
    }
    if(href.indexOf('mz.')!==-1){// 马鞍山
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }
        store.commit('setUrlAh','mz');
        store.commit('setHzType',false);
    }
    if(href.indexOf('cs.')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }
        store.commit('setUrlAh','lc');
        store.commit('setHzType',false);
    }
    if(href.indexOf('cjh.')!==-1){
        if(sdjypxShow){
            await publicFn.getGsUserInfo();
        }
        store.commit('setUrlAh','lc');
        store.commit('setCjhType',false);
        store.commit('setHzType',false);
    }
    if(href.indexOf('gs.')!==-1){
        await publicFn.getGsUserInfo();
        // 甘肃用户是否报名
        // if(localStorage.getItem('userinfo')){
        //     let hrefs=window.location.href;
        //     let subjectId=hrefs.split('subjectId=')[1];
        //     if(subjectId){
        //         let SignUp= await publicFn.getGsSignUpInfoStatus({
        //             param:{
        //                 courseWrapperId:subjectId
        //             }
        //         });
        //         if(SignUp&&SignUp.isEnoll==0){
        //             Modal.confirm({
        //                 title: '提示',
        //                 content: () =>
        //                     <div v-html={SignUp.title}/>
        //                 ,
        //                 okText: '立即报名',
        //                 cancelText: '暂不报名',
        //                 onOk() {
        //                     window.open('https://manage-test.sdjypx.cn/#/user/login')
        //                 },
        //             },);
        //         }
        //     }
        //
        // }

        store.commit('setUrlAh','gs');
        store.commit('setHzType',false);
    }
    // store.commit('setHzType',false);
    // store.commit('setUrlAh','yt');
    // store.commit('setUrlAh','gs');
    // store.commit('setUrlAh','hz1')
    // store.commit('setUrlAh','hz')
    // store.commit('setHzType',true)
    // store.commit('setUrlAh','ahkx')
    // store.commit('setUrlAh','lc')
    // store.commit('setCjhType',true);
    // store.commit('setUrlAhVersion','ahkx1')// 新版
    // store.commit('setUrlAh','ahkx1')
    // store.commit('setUrlAh','ta')
    // store.commit('setHzType',true)
    // store.commit('setUrlAh','ah')
    // store.commit('setUrlAh','mz')
    // store.commit('setUrlAh','ah')
    // store.commit('setUrlAh','zyx')
    // store.commit('setHzType',false);
    // await publicFn.getAhUserInfo();

    const screenWidth = window?.screen?.width || '';
    const screenHeight = window?.screen?.height || '';
    await publicFn.getPlatformMenu({deviceInfo:{
      width:screenWidth,
      height:screenHeight,
      content:window?.navigator?.userAgent || ''
    }});
    await publicFn.getPlatformInfo();
    // await publicFn.getAhUserInfo();
    // await publicFn.getHzUserInfo()
    createApp(App).use(Antd).use(store).use(router).use(print).use(componentPlugin).mount('#app')
}

initAPP()
