import { createStore } from 'vuex'
import layou from './modules/layou'
import examination from './modules/examination'
export default createStore({
  state: {
    cartTotal:0,
    headList:[],
    publicDemand:false,
    urlAh:false,
    urlAhVersion:null,
    HzType:true,
    PlatformName:false,
    ChangingCareers:false,
    Projectinfo:{},//平台信息
    CjhType:false,

  },
  getters: {
  },
  mutations: {
    //设置购物车数量
    setCartTotal:(state,Total)=>{
      state.cartTotal = Total
    },
    //设置head
    setHeadList:(state,List)=>{
      state.headList = List
    },
    setHzType:(state,type)=>{
      state.HzType = type
    },
    setCjhType:(state,type)=>{
      state.CjhType = type
    },
    //设置石头有公需课
    setPublicDemand:(state,type)=>{
      state.publicDemand = type
    },
    //设置是否是安徽或者是安徽科学标志
    setUrlAh:(state,type)=>{
      state.urlAh = type
    },
    //设置是否是安徽或者是安徽科学标志
    setUrlAhVersion:(state,type)=>{
      state.urlAhVersion = type
    },
    //设置转岗培训
    setChangingCareers:(state,type)=>{
      state.ChangingCareers = type
    },
    //设置平台名称
    setPlatformName:(state,Name)=>{
      state.PlatformName = Name
    },
     // 存储平台信息
     setProjectinfo(state,paylod) {
      state.Projectinfo = paylod
    },
  },
  actions: {
  },
  modules: {
    layou,
    examination
  }
})
