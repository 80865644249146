<template>
  <div class="tail">
    <div
      class="ta_b_d"
      :style="{
        'background-image': 'url(' + userinfo.bottomBackground.itemValue + ')',
      }"
    ></div>
    <div class="box_bb">
      <div class="nav widthcontent">
        <div
          class="ta_b_ewm"
          v-if="
            ['zyx', 'mz','gs'].some((item) => {
              return $store.state.urlAh == item
            })
          "
        >
          <img
            class="ta_b_ewm_img"
            v-if="userinfo && userinfo.wxOffiAccount"
            :src="userinfo.wxOffiAccount.itemPath"
            alt=""
          />
          <div class="ta_b_ewm_txt" v-if="userinfo && userinfo.wxOffiAccount">
            {{ userinfo.wxOffiAccount.itemName }}
          </div>
        </div>
        <div
          class="ta_b_ewm"
          v-if="
            ['lc', 'ahkx1', 'yt', 'hz1'].some((item) => {
              return item === $store.state.urlAh
            })
          "
        >
          <img
            class="ta_b_ewm_img"
            v-if="userinfo && userinfo.wxOffiAccount"
            :src="userinfo.wxOffiAccount.itemPath"
            alt=""
          />
          <div class="ta_b_ewm_txt" v-if="userinfo && userinfo.wxOffiAccount">
            {{ userinfo.wxOffiAccount.itemName }}
          </div>
        </div>
        <!--        <div class="ta_b_ewm" v-if="$store.state.urlAhVersion == 'ahkx1'">-->
        <!--          <img-->
        <!--            class="ta_b_ewm_img"-->
        <!--            v-if="userinfo && userinfo.wxOffiAccount"-->
        <!--            :src="userinfo.wxOffiAccount.itemPath"-->
        <!--            alt=""-->
        <!--          />-->
        <!--          <div class="ta_b_ewm_txt" v-if="userinfo && userinfo.wxOffiAccount">-->
        <!--            {{ userinfo.wxOffiAccount.itemName }}-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="ta_b_ewm" v-else>
          <template
            v-if="
              !['ta', 'lc', 'zyx', 'mz','gs'].some((item) => {
                return item === $store.state.urlAh
              })
            "
          >
            <img
              class="ta_b_ewm_img"
              v-if="userinfo && userinfo.wxAppCode"
              :src="userinfo.wxAppCode.itemPath"
              alt=""
            />
            <div class="ta_b_ewm_txt" v-if="userinfo && userinfo.wxAppCode">
              {{ userinfo.wxAppCode.itemValue }}
            </div>
          </template>
        </div>
        <div v-if="hzShow" class="b_t_ul_box">
          <div class="b_t_li">
            {{ userinfo.phone.itemValue }}
          </div>
          <div class="b_t_li">
            {{ userinfo.address.itemValue }}
          </div>
          <div class="b_t_li">
            {{ userinfo.name.itemValue }}
          </div>
        </div>
        <div v-else class="b_t_ul_box">
          <!-- <div class="b_t_li" v-for="(it,index) in Configuration.shanxi.Bottombar.left" :key="index">{{it.text}}</div> -->
          <!-- 联系电话，联系我们 -->
          <div class="b_t_li">
            {{ userinfo.phone.itemValue }} | {{ userinfo.address.itemValue }}
          </div>
          <div class="b_t_li">
            {{ userinfo.name.itemValue }} | {{ userinfo.filingNo.itemValue }}
          </div>
          <div class="b_t_li">
            {{ userinfo.videoNo.itemValue }} |
            {{ userinfo.telecomNo.itemValue }}
          </div>
          <div
            v-if="
              ['hz', 'ta', 'lc', 'ah', 'ahkx', 'yt', 'hz1'].some((item) => {
                return item === $store.state.urlAh
              })
            "
          >
            <a
              href="https://xyt.xcc.cn/getpcInfo?sn=1700059454617145344&language=CN&certType=8&url=*.tyjrpx.com"
              target="_blank"
              style="position: relative; display: inline-block; height: 38px"
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
              ></div>
              <embed
                src="https://program.xinchacha.com/web/1700059454617145344=*.tyjrpx.com.svg"
                width="103"
                height="38"
                type="image/svg+xml"
                pluginspage="//www.adobe.com/svg/viewer/install/"
            /></a>
          </div>
          <div
            v-else-if="
              ['zyx', 'ahkx1', 'mz','gs'].some((item) => {
                return item === $store.state.urlAh
              })
            "
          >
            <a
              href="https://xyt.xcc.cn/getpcInfo?sn=1700039452769898496&language=CN&certType=8&url=*.peixun.city"
              target="_blank"
              style="position: relative; display: inline-block; height: 38px"
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
              ></div>
              <embed
                src="https://program.xinchacha.com/web/1700039452769898496=*.peixun.city.svg"
                width="103"
                height="38"
                type="image/svg+xml"
                pluginspage="//www.adobe.com/svg/viewer/install/"
            /></a>
          </div>
        </div>
        <div>
          <div class="b_t_ubh">快捷导航</div>
          <div class="b_t_ul_center">
            <div
              class="b_t_ul"
              @click="
                locationhref(
                  'http://www.mohrss.gov.cn/',
                  '中华人民共和国人力资源和社会保障部'
                )
              "
            >
              <div>
                <img class="b_r_img" src="../../assets/img/guohui.png" alt="" />
              </div>
              <div class="b_r_txet">中华人民共和国人力资源和社会保障部</div>
            </div>
            <div
              class="b_t_ul"
              @click="locationhref(it.webUrl, it.partnerName)"
              v-for="(it, index) in PartnerList"
              :key="index"
            >
              <div>
                <img class="b_r_img" src="../../assets/img/guohui.png" alt="" />
              </div>
              <div class="b_r_txet">
                {{ it.partnerName }}
                <!--                {{userinfo.socialOffice&&userinfo.socialOffice.itemValue}}-->
              </div>
            </div>
          </div>
          <!--          <div v-if="$store.state.urlAh==='mz'"  class="b_t_ul" style="cursor: pointer"  @click="locationhref(-->
          <!--                  'https://ah.peixun.city/Index',-->
          <!--                  '安徽专技网'-->
          <!--                )-->
          <!--              ">安徽专技网</div>-->
          <div class="shenju" v-if="false">
            <!-- <a-select
            v-model:value="topicValue"
            :fieldNames="fieldNames"
            label-in-value
            style="width: 100%"
            :options="frame"
            @change="handleChange"
            @select="franselect"
          ></a-select> -->
            <!--            <Secondary></Secondary>-->
          </div>
        </div>
      </div>
    </div>
    <Verification Title="跳转提示" v-model:show="jumptype" width="650px">
      <template #neirong>
        <div class="tk_top">确认是否进入{{ sunitem.label }}</div>
        <div class="tk_bottom">
          <div class="tk_qr" @click="qrtottem">确认</div>
          <div class="tk_qx" @click="qxtottem">取消</div>
        </div>
      </template>
    </Verification>
  </div>
</template>

<script>
import Configuration from "@/util/Configuration"
import { ref, reactive, toRefs } from "@vue/reactivity"
import { useStore } from "vuex"
import publicFn from "@/util/publicFn"
import Verification from "@/components/Verification/index.vue"
import Secondary from "@/components/Secondary/index.vue"
export default {
  name: "ZjTail",
  components: {
    Verification,
    Secondary,
  },
  setup() {
    let store = useStore()
    const hzShow = ref(false)
    let pages = store.state.urlAh
    if (pages == "hz1") {
      hzShow.value = true
    }
    let data = reactive({
      // 弹窗开关
      jumptype: false,
      // 选择的内容
      topicValue: "安徽省各地市人力资源和社会保障局",
      // 存放数据
      frame: [],
      //地址栏数据
      PartnerList: [],
      // 内容
      userinfo: store.state.layou.Displays || [],
      merchantId: 88888888,
      // 跳转平台数据
      sunitem: {},
      //跳转平台
      locationhref(e, name) {
        // window.open(e)
        data.sunitem.value = e
        data.sunitem.label = name
        data.jumptype = true
      },
      handleChange: () => {
        // window.location.href = e.value
      },
      // 点击下拉选项
      franselect(e) {
        data.sunitem.value = e
        data.jumptype = true
      },
      topic: [
        {
          value: "0",
          label: "山西省各地市人力资源和社会保障局",
        },
        {
          value: "1",
          label: "山东省各地市人力资源和社会保障局",
        },
      ],
      // 保障局地址
      // getToplist() {
      //   publicFn.post({
      //     serviceCode:'getCooperativePartnerList',
      //     param:{
      //       webType:1
      //     }
      //   }).then((res)=>{
      //     data.frame = res.rows
      //   })
      // },
      //平台地址
      getplatformlist() {
        publicFn
          .post({
            serviceCode: "getCooperativePartnerList",
            param: {
              pid: 0,
            },
          })
          .then((res) => {
            data.PartnerList = res.rows
          })
      },
      // 类型选择
      fieldNames: {
        label: "partnerName",
        value: "webUrl",
      },
      // 确认进入相关平台
      qrtottem() {
        window.open(data.sunitem.value)
        data.jumptype = false
      },
      // 取消进入平台
      qxtottem() {
        data.jumptype = false
      },
    })
    // data.getToplist()
    // data.getplatformlist()
    data.getplatformlist()
    return {
      Configuration,
      ...toRefs(data),
      hzShow,
    }
  },
}
</script>
<style>
.tail .ant-select-arrow {
  color: #fff;
}
</style>
<style scoped>
.tail {
  bottom: 0;
  width: 100%;
}
.nav {
  margin: auto;
  width: 1220px;
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
}
.box_bb {
  /* display: flex;
  justify-content: center; */
  color: #fff;
  background: #0246bf;
}
.box_bb_tx {
  display: flex;
  justify-content: space-between;
}
.ta_b_d {
  height: 180px;
  /* background-image: url('../../assets/img/zxdb.png'); */
  background-repeat: no-repeat;
  background-size: 100% 101%;
}
.b_t_ul {
  width: 300px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.b_t_ul_center {
  width: 600px;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-top: 10px;
}
.b_t_ul_box {
  margin-top: 5px;
}
.b_t_li {
  margin: 22px 0;
  font-size: 12px;
}
/* .b_t_li:nth-child(1) {} */
.b_t_ub {
  margin-top: 24px;
}
.b_t_ubh {
  margin-top: 25px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
}
.b_r_img {
  width: 21px;
  height: 22px;
}
.shenju {
  width: 280px;
  border: 1px solid #fff;
  border-radius: 4px;
  /* padding: 2px 4px ; */
  /* align-items: center; */
}
.b_r_txet {
  margin-left: 10px;
  cursor: pointer;
}
.ta_b_ewm {
  position: absolute;
  top: -150px;
  left: 0;
  width: 111px;
  height: 111px;
  border-radius: 4px;
}
.ta_b_ewm_txt {
  font-size: 16px;
  position: absolute;
  bottom: -35px;
  left: 16%;
}
.shenju_span {
  margin-top: 10px;
  margin-left: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: #fff;
}
.ta_b_ewm_img {
  width: 120px;
  height: 120px;
  border-radius: 4px;
}
.ant-select:not(.ant-select-customize-input) /deep/ .ant-select-selector {
  background: transparent !important;
  color: #fff !important;
  border: 1px solid transparent !important;
  /* height: 30px !important; */
}
.ant-select-selector /deep/ .ant-select-arrow /deep/ .anticon > svg {
  color: #fff !important;
}

/* .tail .shenju .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  font-size: 13px !important;
  height: 21px !important;
  line-height: 20px !important;
} */
.shenju .ant-select {
  height: 24px !important;
}
.shenju
  >>> .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  line-height: 22px;
}
.shenju .ant-select-arrow {
  line-height: 24px;
  height: 24px;
}
.tk_top {
  font-size: 22px;
}
.tk_bottom {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}
.tk_qr {
  font-size: 18px;
  background: #0b62ee;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  margin-left: 15px;
  cursor: pointer;
}
.tk_qx {
  font-size: 18px;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}
</style>

<style>
.tail
  .shenju
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  font-size: 12px !important;
  /* height: 21px !important;
  line-height: 20px !important; */
}
.tail
  .shenju
  .Secondary
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  color: #fff;
  border: 0 solid #000 !important;
}
/* .tail .shenju .ant-select-arrow .anticon {
  margin-top: 8px !important;
}  */
/* .tail.shenju .ant-select {
 height: 15px !important;
 line-height: 15px !important;
} */
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  line-height: 0 !important;
} */
</style>
