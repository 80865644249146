<template>
  <div class="hzBox" v-if="typePage === 'hz'">
    <div class="box_f">
      <div :class="noline ? 'area_top' : 'area_top_noline'">
        <div class="top_l">
          <div v-if="logimg !== ''">
            <img class="top_img" :src="logimg" alt="" />
          </div>
          <div :class="'b_txt_ah'">
            {{ title }}
          </div>
        </div>
        <div>
          <div
            v-if="moreShow"
            :class="['ah','gs'].some(item=>{return typePage==item}) ? 'b_txt_r_ah' : 'b_txt_r'"
            @click="goMore(toPath || '')"
          >
            查看更多>
          </div>
        </div>
      </div>
      <div class="">
        <slot name="xiangqing"></slot>
      </div>
    </div>
  </div>
  <div class="box" v-else>
    <div class="box_f min-height">
      <div :class="noline ? 'area_top' : 'area_top_noline'">
        <div class="top_l">
          <div v-if="logimg !== ''">
            <img class="top_img" :src="logimg" alt="" />
          </div>
          <div :class="['ah','gs'].some(item=>{return typePage==item}) ? 'b_txt_ah' : 'b_txt'">
            {{ title }}
          </div>
        </div>
        <div>
          <div
            v-if="moreShow"
            :class="['ah','gs'].some(item=>{return typePage==item}) ? 'b_txt_r_ah' : 'b_txt_r'"
            @click="goMore('/Policyadvice')"
          >
            查看更多>
          </div>
        </div>
      </div>
      <div class="">
        <slot name="xiangqing"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import publicFn from '@/util/publicFn.js'

export default {
  name: 'ZjDisplay',
  props: {
    title: {
      type: String,
      default: '政策公告',
    },
    logimg: {
      type: String,
      require: true,
      default: '',
    },
    typePage: {
      type: String,
      default: 'hz',
    },
    moreShow: {
      type: Boolean,
      default: true,
    },
    noline: {
      type: Boolean,
      default: true,
    },
    toPath: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      goMore: publicFn.routeJump,
    }
  },
}
</script>

<style scoped>
.min-height {
  min-height: 400px;
}
.b_txt_r_ah {
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  cursor: pointer;
}
.b_txt_r_ah:hover {
  color: #1167f2;
}
.b_txt_ah {
  font-size: 25px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #222222;
  line-height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.hzBox {
  width: 100%;
  min-width: 430px;
}
.box {
  width: 100%;
  min-width: 680px;
}
.area_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 16px;
}
.area_top-noline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 16px;
}
.top_l {
  display: flex;
  align-items: center;
}
.b_txt {
  color: #222222;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 30px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
}
.b_txt_x {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.b_txt_r {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1168f3;
  cursor: pointer;
}
.top_img {
  width: 30px;
  height: 30px;
}
</style>
