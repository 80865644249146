<template>
  <div class="Centerlabel">
    <div class="gwc">
      <!-- <div class="gwc_a_l">
        <img class="gwc_log" src="../../assets/img/lingdang.png" alt="">
        <div class="gwc_sl">12</div>
      </div> -->
      <!-- <div class="gwc_a" @click="opencartf">
        <img class="gwc_log" src="../../assets/img/gouwuche.png" alt="">
        <div class="gwc_sl">{{$store.state.cartTotal}}</div>
      </div> -->
    </div>
    <div class="box_div" v-if="['ta', 'yt', 'lc', 'hz1', 'zyx','mz'].some(v => v === $store.state.urlAh)">
      <div
        v-if="['ta', 'yt', 'lc', 'hz1', 'zyx','mz'].some(v => v === $store.state.urlAh)"
        class="box"
      >
        <div
          class="taBox_t_l"
          v-if="$store.state.layou.token"
          @click="toPersonal"
        >
          个人中心
        </div>
        <div
          class="tnOutBox_t_r"
          v-if="$store.state.layou.token"
          @click="clickout"
        >
          退出登录
        </div>
        <div class="taBox_t_r" @click="linktogl" v-else>
          {{sdjypxShow?loginName:$store.state.urlAh === 'hz1' ? ' 菏泽市继续教育管理平台' : $store.state.urlAh === 'yt'?'点击注册登录':'点击登录注册'}}
<!--          {{ $store.state.urlAh === 'hz1' ? ' 菏泽市继续教育管理平台' : $store.state.urlAh === 'yt'?'点击注册登录':'点击登录注册' }}-->
        </div>
      </div>
      <div v-if="$store.state.layou.token" style="clear: both"></div>
      <div v-if="$store.state.layou.token" class="box_kf">
        <div class="kf">
          {{ $store.state.layou.Displays.operatorPhone.itemName }} :
          {{ $store.state.layou.Displays.operatorPhone.itemValue }}
        </div>
        <div>
          {{
            `(${$store.state.layou.Displays.workTime.itemName}:${$store.state.layou.Displays.workTime.itemValue})`
          }}
        </div>
      </div>
    </div>
    <div v-else class="box_div">
      <div
        v-if="$store.state.urlAh === 'hz1' || $store.state.urlAh === 'ta'"
        class="box"
      >
        <div class="box_t_l" @click="toPersonal">个人中心</div>
        <div class="box_t_r" v-if="$store.state.layou.token" @click="clickout">
          退出登录
        </div>
        <div class="box_t_r" @click="linktogl" v-else>
          {{sdjypxShow?loginName:$store.state.urlAh === 'hz1' ? ' 菏泽市继续教育管理平台' : $store.state.urlAh === 'yt'?'点击注册登录':'点击登录注册'}}
<!--          {{-->
<!--            $store.state.urlAh === 'hz1'-->
<!--              ? ' 菏泽市继续教育管理平台'-->
<!--              : '注册登录'-->
<!--          }}-->
        </div>
      </div>
      <div v-else class="box">
        <template v-if="$store.state.layou.token">
          <div class="box_t_l" @click="toPersonal">个人中心</div>
          <div class="box_t_r"  @click="clickout">退出登录</div>
        </template>
      </div>
      <div style="clear: both"></div>
      <div class="box_kf">
        <div class="kf">
          {{ $store.state.layou.Displays.operatorPhone.itemName }} :
          {{ $store.state.layou.Displays.operatorPhone.itemValue }}
        </div>
        <div>
          <!-- {{
            $store.state.urlAh == 'zyx'
              ? '(工作日:上午08:30-12:00 下午13:30-18:00)'
              : '(工作日:上午08:30-12:00 下午13:00-18:00)'
          }} -->
          {{
            `(${$store.state.layou.Displays.workTime.itemName}:${$store.state.layou.Displays.workTime.itemValue})`
          }}
        </div>
      </div>
    </div>
    <pay ref="payxx"></pay>
    <!--    泰安登录弹窗-->
    <loginPopup ref="loginPopupref" @Jump="fnJump" />
    <!--   泰安忘记密码-->
    <forgotpasswords ref="forgotpasswordRef" />
    <!--   泰安注册-->
    <register ref="registerRef" />
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import http from '@/util/http'
import publicFn from '@/util/publicFn'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { watch } from 'vue'
import { pay } from '@/components/payorder'
import loginPopup from './loginPopup.vue'
import forgotpasswords from '@/components/Signin/forgotpassword.vue'
import register from '@/components/Signin/register.vue'
import  utils  from '@/util/utilsIndex'
export default {
  name: 'ZjCenterlabel',
  components: {
    pay,
    loginPopup,
    forgotpasswords,
    register,
  },
  setup() {
    const forgotpasswordRef = ref()
    const registerRef = ref()
    let store = useStore()
    const Router = useRouter();
    function toPersonal() {
      Router.push({
        path: '/Personal',
      })
    }

    let data = reactive({
      sdjypxShow:window.location.href.indexOf('sdjypx')!==-1?true:false,
      loginName:utils.loginName()||'',
      userinfo: store.state.layou.userinfo,
      payxx: null, //购物车
      loginPopupref: null, //泰安登录页
      registerRef: null, //泰安注册页
      //打开购物车
      opencartf() {
        data.payxx.opencart()
      },
      // 泰安忘记密码和注册跳转
      fnJump(num) {
        if (num === 2) {
          forgotpasswordRef.value.registershow()
        } else {
          data.registerRef.getParentdata()
        }
      },
      // 退出登录
      clickout: () => {
        http
          .post({
            serviceCode: 'logoutAccount',
            token: data.userinfo.token,
            uid: data.userinfo.uid,
          })
          .then((res) => {
            publicFn.routeJump({
              path: '/index',
            })
            store.commit('layou/removeToken')
            data.userinfo = {}
            message.success(res.message)
          })
          .catch(() => {
            publicFn.routeJump({
              path: '/index',
            })
          })
      },
    })
    const linktogl = () => {

      if( data.sdjypxShow){//测试环境
        window.location.href = 'https://manage-test.sdjypx.cn/#/user/login'
      }else{//正式环境
        if (store.state.urlAh === 'hz1') {
          //菏泽促进会跳转网址
          window.location.href = 'http://mh.shunjy.com:8090/#/'
        } else {
          //泰安登录弹窗
          data.loginPopupref.getParentdata()
        }
      }

    }
    watch(
      () => store.state.layou.userinfo,
      (newl) => {
        data.userinfo = newl
      }
    )
    return {
      registerRef,
      forgotpasswordRef,
      toPersonal,
      ...toRefs(data),
      linktogl,
    }
  },
}
</script>

<style scoped>
.box_div {
  position: relative;
}
.kf {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;
}
.box_kf {
  color: #fff;
  font-size: 13px;
  text-align: right;
}
.Centerlabel {
  position: relative;
}
.gwc {
  position: absolute;
  top: 5px;
  left: -55px;
  display: flex;
}
.gwc_a {
  position: relative;
  margin-left: 40px;
  cursor: pointer;
}
.gwc_a_l {
  position: relative;
  cursor: pointer;
}
.gwc_a:hover .gwc_sl,
.gwc_a_l:hover .gwc_sl {
  color: #d83535;
}
.gwc_log {
  width: 26px;
  height: 26px;
}
.gwc_sl {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 22px;
  height: 22px;
  line-height: 18px;
  background: #0059e8;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  border: 2px solid #ffffff;
}
.box {
  cursor: pointer;
  float: right;
  display: flex;
}
.box_t_l {
  font-size: 18px;
  color: #fff;
  padding: 4px 14px;
  background: #f1822e;
  border-radius: 4px 0px 0px 4px;
  font-weight: 800;
}
.taBox_t_l {
  font-size: 18px;
  color: #fff;
  padding: 4px 14px;
  background: #f1822e;
  border-radius: 20px 0px 0px 20px;
  font-weight: 800;
}
.box_t_r {
  font-size: 18px;
  color: #333;
  padding: 4px 14px;
  background: #e8e8ff;
  border: 1px solid #ffffff;
  border-radius: 0px 4px 4px 0px;
  font-weight: 800;
}
.tnOutBox_t_r {
  font-size: 18px;
  color: #ea8530;
  padding: 4px 14px;
  background: #faf6e7;
  border: 1px solid #ea8530;
  border-radius: 0px 20px 20px 0px;
  font-weight: 800;
}
.taBox_t_r {
  font-size: 18px;
  color: #333;
  padding: 4px 14px;
  background: #e8e8ff;
  border: 1px solid #ffffff;
  border-radius: 0px 4px 4px 0px;
  font-weight: 800;
}
.taBox_t_r {
  /*width: 210px;*/
  height: 64px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background: #db7d3a;
  border: 1px solid #ffffff;
  border-radius: 32px;
  font-weight: 800;
}
</style>
