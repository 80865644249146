<template>
  <a-modal
    v-model:visible="registertype"
    :maskClosable="false"
    :footer="null"
    width="650px"
    :closable="false"
  >
    <template #title>
      <div class="titlebox">注册</div>
    </template>
    <a-form
      :model="register"
      @finish="registeronFinish"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-item
        label="手机号码"
        name="phone"
        :rules="[{ required: true, validator: phonerule }]"
        placeholder="请输入手机号"
      >
        <a-input v-model:value="register.phone" placeholder="请输入手机号" />
      </a-form-item>
      <a-form-item
        label="手机验证码"
        name="phoneCode"
        :rules="[{ required: true, message: '请输入验证码!' }]"
        placeholder="请输入验证码"
      >
        <div class="phoneCode">
          <div class="phoneCode_l">
            <a-input
              v-model:value="register.phoneCode"
              placeholder="请输入验证码"
            />
          </div>
          <div class="phoneCode_r" @click="sendPhoneCode">
            {{ btnText }}
          </div>
        </div>
      </a-form-item>
      <a-form-item
        label="所属区域"
        name="qy"
        :rules="[{ required: true, message: '请输入所属区域!' }]"
        placeholder="请输入所属区域"
      >
        <cascader
          v-model:modelValue="register.qy"
          placeholder="请输入所属区域"
        ></cascader>
      </a-form-item>
      <a-form-item
        v-if="['lc','ta','yt'].some(item=>{return item==$store.state.urlAh})"
        name="companyName"
        :required="true"
        label="单位名称"
        placeholder="请输入单位名称"
      >
             <span class="h_com_tip"
                   v-if="['ta','lc','yt' ].some((v) => v === $store.state.urlAh)">（ 没有您的单位？请<span
                 class="f_c_0952da"
                 @click="changeInput">
                      点击此处输入</span>， 请确保所属单位信息正确）</span>
        <a-input v-show="companyinPutshow" v-model:value="register.companyName" placeholder="请输入所属单位"></a-input>
        <a-select
            v-show="!companyinPutshow"
            show-search
            v-model:value="register.companyName"
            :fieldNames="fieldcompanyId"
            :filter-option="filtersOption"
            style="width: 100%"
            placeholder="请选择所属单位"
            :options="companyIdoptions"
        ></a-select>
      </a-form-item>
      <a-form-item
        label="密码"
        name="pwd"
        :rules="[{ required: true, message: '请输入密码!' }]"
      >
        <a-input-password
          v-model:value="register.pwd"
          placeholder="请输入密码"
          autocomplete="new-password"
        />
      </a-form-item>
      <a-form-item
        label="确认密码"
        name="pwdc"
        :rules="[{ required: true, validator: validatePass2 }]"
      >
        <a-input-password
          v-model:value="register.pwdc"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item
        label="姓名"
        name="realName"
        :rules="[{ required: true, message: '请输入姓名!' }]"
      >
        <a-input v-model:value="register.realName" placeholder="请输入姓名" />
      </a-form-item>
      <a-form-item
        label="身份证号"
        name="identityNo"
        :rules="[{ required: true, validator: phoneNumberCheck }]"
        placeholder="请输入手机号"
      >
        <a-input
          v-model:value="register.identityNo"
          placeholder="请输入身份证号"
        />
      </a-form-item>
      <!--          仅泰安聊城烟台有专业选修-->
      <template v-if="['ta','lc','yt'].some(item=>{return item=== $store.state.urlAh})&&technicalJobculum&&technicalJobculum.length!=0">
        <a-form-item label="专业技术职称系列" >
          <a-select
              v-model:value="register.technicalJob"
              :fieldNames="{ label: 'itemName', value: 'itemValue' }"
              style="width: 100%"
              placeholder="请选择专业技术职称系列"
              :options="technicalJobculum"
          />
        </a-form-item>
      </template>
      <a-form-item :wrapper-col="{ span: 16, offset: 6 }">
        <div class="fg_pwd_buttonbox">
          <a-button
            class="fg_pwd_btn_l"
            @click="registertype = false"
            size="large"
            >取消</a-button
          >
          <a-button
            class="fg_pwd_btn_l"
            style="margin-left: 15px"
            type="primary"
            html-type="submit"
            size="large"
            >注册</a-button
          >
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import cascader from '@/components/Signin/cascader.vue'
import http from '@/util/http'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import publicFn from "@/util/publicFn";
import {onMounted} from "@vue/runtime-core";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'registers',
  components: { cascader },
  setup() {
    let store = useStore()
    let data = reactive({
      companyIdoptions: [],
      technicalJobculum:[],
      // 单位选择
      fieldcompanyId: {
        label: 'companyName',
        value: 'companyId',
        options: 'companyIdoptions',
      },
      // 获取所属单位
      getcompanyId() {
        publicFn
            .post({
              serviceCode: 'getCompanyList',
            })
            .then((res) => {
              data.companyIdoptions = res.rows
            })
      },
      registertype: false,
      btnText: '发送验证码',
      isClick: false,
      currentTime: 60,
      register: {
        companyId:null,
        companyName:null,
      },
      companyinPutshow:false,
       changeInput(){
        data.companyinPutshow = !data.companyinPutshow
        data.register.companyName = ''
        data.register.companyId = ''
      },
      filtersOption: (input, option) => {
        return (
            option.companyName.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      },
      //注册提交
      registeronFinish(val) {
        if(['lc','ta','yt'].some(item=>{return item== store.state.urlAh})){
          if(data.register.companyId||data.register.companyName){
            if(!data.companyinPutshow){
              val.companyId=val.companyName;
              val.companyName=data.companyIdoptions.find(item=> item.companyId== val.companyName).companyName;
            }
          }else{
            return message.warning('请选择或输入单位名称')
          }
        }
        if(!val.qy.provinceCode){
          return message.warning('请选择所在区域省份')
        }
        if(!val.qy.cityCode){
          return message.warning('请选择所在区域市区')
        }
        if(!val.qy.countyCode){
          return message.warning('请选择所在区域县')
        }

        http.post({
            serviceCode: 'regUserInfo',
            param: {
              ...val,
              identityType: 1,
              province: val.qy.provinceCode,
              city: val.qy.cityCode,
              county: val.qy.countyCode,
            },
          })
          .then((res) => {
            localStorage.setItem('userinfo', JSON.stringify(res))
            store.commit('layou/setUserinfo', res)
            message.success(res.message)
            store.commit('layou/setavatar', res.userInfo.avatar)
            data.registertype = false
          })
      },
      //发送验证码
      sendPhoneCode() {
        if (data.isClick) {
          return false
        }
        //验证手机号
        if (!data.yzphone()) {
          return false
        }
        data.isClick = true
        let currentTime = data.currentTime
        http
          .post({
            serviceCode: 'sendPhoneCode',
            param: {
              phone: data.register.phone,
              type: 2,
            },
          })
          .then(() => {
            let timeInterval = setInterval(function () {
              currentTime-- //倒计时
              data.btnText = currentTime + ''
              if (currentTime <= 0) {
                clearInterval(timeInterval) //重置倒计时
                data.btnText = '发送验证码'
                data.currentTime = 60
                data.isClick = false
              }
            }, 1000)
            message.success('发送验证码成功')
          })
          .catch(() => {
            data.isClick = false
          })
      },
      //发送验证码验证手机号
      yzphone() {
        if (data.register.phone === '') {
          message.error('请输入手机号')
          return false
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(data.register.phone)) {
          message.error('手机号格式不正确')
          return false
        } else {
          return true
        }
      },
      // 父级页面调用
      getParentdata() {
        for (const key in data.register) {
          data.register[key] = ''
        }
        data.getcompanyId();
        data.registertype = true
      },
      // 获取专业列表
      fnTechnicalJobculum(){
        publicFn
            .post({
              serviceCode: 'getCourseScreen',
              param: {
                dictCode: 'professionalSeries',
              },
            })
            .then((res) => {
              data.technicalJobculum = res.rows
            })
      },
    });
    onMounted(()=>{
      if(['yt','lc','ta'].some(item=>{return item=== store.state.urlAh})){
        data.fnTechnicalJobculum();
      }
    })
    return {
      ...toRefs(data),
    }
  },
}
</script>

<style scoped>
.f_c_0952da {
  color: #0952da;
  cursor: pointer;
}
.h_com_tip {
  font-size: 12px;
  color: red;
}
.phoneCode {
  display: flex;
  justify-content: space-between;
}

.phoneCode_l {
  width: calc(100% - 100px);
}

.phoneCode_r {
  width: 100px;
  background: #40a9ff;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  height: 32px;
}
.fg_pwd_buttonbox {
  text-align: right;
}
.fg_pwd_btn_l {
  width: 100px;
}
</style>
