<template>
  <div class="Area widthcontent">
    <div class="area_top">
      <div class="top_l">
        <div class="b_txt">{{ title }}</div>
      </div>
      <div class="top_r">
        <div>搜索课程：</div>
        <div>
          <a-input-search
            v-model:value="value"
            placeholder="请输入您要搜索的关键词"
            enter-button="搜索"
            @search="onSearch"
          />
        </div>
        <div class="top_r_s">
          <img class="ht_img" src="../../assets/img/zjhuantiao.png" alt="" />
          <img class="ht_img_ban" src="../../assets/img/zxhuanban.png" alt="" />
          <div class="top_r_s_t">
            <img class="gwc_img" src="../../assets/img/zjgouwuche.png" alt="" />
            已选20.0学时 | 总计2门课程
          </div>
          <div class="top_r_s_tb">报名</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="ul_Li" v-for="(item, index) in list" :key="index">
        <div class="ul_Li_li" @click="goDetails(index)">
          <div class="ul_Li_imgf">
            <img class="ul_Li_img" :src="item.img" alt="" />
          </div>
          <div class="ul_li_txt">
            <div class="ul_li_bt">{{ item.dictLabel }}</div>
            <div class="ul_li_baom">
              报名期限:{{ item.createTime }}至{{ item.updateTime }}
            </div>
            <div class="ul_li_foot">
              <div>可得学时:{{ item.dictSort }}学时</div>
              <div class="ul_li_foot_txt" v-if="!item.mayne">免费</div>
              <div class="ul_li_foot_txt2" v-if="item.mayne && userinfoShow">
                ￥{{ item.mayne }}
              </div>
            </div>
            <div class="ul_li_bx">
              <img
                class="ul_li_btt_bx"
                src="../../assets/img/bixui.png"
                alt=""
              />
            </div>
            <div class="ul_li_btt">
              <img
                class="ul_li_bimg"
                src="../../assets/img/youshangbianqian.png"
                alt=""
              /><span class="ul_li_b_txt">2022</span>
            </div>
          </div>
          <div class="ul_Li_h">
            <div class="ul_Li_h_t1">立即报名</div>
            <div class="ul_Li_h_t2">加入购物车</div>
            <div class="ul_Li_h_t2">试看课程</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  name: 'ZjZreaindex',
  props: {
    list: {
      type: Object,
    },
    title: {
      type: String,
      default: '专业课学习',
    },
  },
  setup() {
    const store = useStore()
    const Router = useRouter()
    let data = reactive({
      value: '',
      userinfoShow: true,
      // 用来判断是不是众益学 若是众益学未登录不显示价格
      fnPanduan() {
        let userinfo = localStorage.getItem('userinfo')
        if (!userinfo && store.state.urlAh == 'zyx') {
          data.userinfoShow = false
        } else {
          data.userinfoShow = true
        }
      },
    })
    // let list = reactive(
    //   [{
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject:'农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '5',
    //   Price:''
    // },

    // {
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject: '农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '5',
    //   Price:''
    // },
    // {
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject: '农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '',
    //   mayne:'15',
    //   Price:''
    // },
    // {
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject: '农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '',
    //   mayne:'15',
    //   Price:''
    // },{
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject: '农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '',
    //   mayne:'15',
    //   Price:''
    // },
    // {
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject: '农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '',
    //   mayne:'15',
    //   Price:''
    // },
    // {
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject: '农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '',
    //   mayne:'15',
    //   Price:''
    // },
    // {
    //   img:'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/cf634215cd6947e09f7518ea1b27e8a9.jpg',
    //   subject: '农村高质量发展和乡村振兴...',
    //   starttime: '2021-08-03',
    //   endtime: '2022-1-31',
    //   hours: '5',
    //   Price:''
    // }]
    // )
    // 点击商品跳转详情
    function goDetails(i) {
      console.log(`点击第${i}个`)
      if (store.state.urlAh == 'hz' || store.state.urlAh == 'hz1') {
        Router.push({ path: `/VideoDetails1` })
      } else {
        Router.push({ path: `/VideoDetails` })
      }
    }
    // 跳转查看更多
    function goMore() {
      console.log('跳转查看更多系列')
    }
    let onSearch = (e) => {
      console.log(e)
    }
    onMounted(() => {
      data.fnPanduan()
    })
    return {
      // list,
      goDetails,
      goMore,
      onSearch,
      ...toRefs(data),
    }
  },
}
</script>
<style scoped>
.Area {
  width: 1220px;
  margin: 0 auto;
  background: #fff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 26px;
}
.top_r {
  display: flex;
  align-items: center;
}
.ul_li_bx {
  position: absolute;
  left: -2px;
  top: -2px;
}
.ul_li_btt_bx {
  width: 67px;
  height: 33px;
}
.top_r_s {
  position: relative;
}
.ht_img {
  width: 336px;
  height: 46px;
  margin-left: 42px;
}
.ht_img_ban {
  width: 94px;
  height: 46px;
}
.top_r_s_t {
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}
.gwc_img {
  width: 26px;
  height: 24px;
}
.top_r_s_tb {
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}
.area_top {
  display: flex;
  justify-content: space-between;
  background: #fff;
}
.top_l {
  display: flex;
  align-items: center;
}
.b_txt {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #222222;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  border-bottom: 5px solid #2878ff;
}
.b_txt_x {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.b_txt_r {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1168f3;
  cursor: pointer;
}
.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 31px;
}
.ul_Li {
  width: 267px;
  height: 280px;
  position: relative;
  margin-bottom: 38px;
  cursor: pointer;
}
.ul_Li_img {
  width: 267px;
  height: 156px;
  transition: all 1s;
}
.ul_Li_imgf {
  width: 267px;
  height: 156px;
  overflow: hidden;
}
.ul_li_bt {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 10px;
}
.ul_li_txt {
  padding-left: 22px;
  padding-right: 16px;
}
.ul_li_baom {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.ul_li_foot {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.ul_li_foot_txt {
  width: 50px;
  height: 20px;
  background: #f7dca8;
  border-radius: 4px;
  text-align: center;
}
.ul_li_bimg {
  width: 87px;
  height: 75px;
}
.ul_li_btt {
  position: absolute;
  top: -5px;
  right: -5px;
}
.ul_li_b_txt {
  position: absolute;
  transform: rotate(45deg);
  left: 35px;
  top: 15px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}
.ul_Li_li {
  border: 1px solid #e5e5e5;
  padding-bottom: 30px;
}
.ul_Li_li:hover .ul_Li_img {
  transform: scale(1.2);
}
.ul_li_foot_txt2 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: red;
}
.ul_Li_h {
  display: none;
}
.ul_Li_li:hover .ul_Li_h {
  display: block;
  width: 267px;
  height: 310px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  text-align: center;
}
.ul_Li_h_t1 {
  width: 171px;
  margin: 0 auto;
  height: 47px;
  line-height: 47px;

  background: #fe8820;
  border-radius: 6px;
  margin-top: 80px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.ul_Li_h_t2 {
  margin: 0 auto;
  margin-top: 24px;
  width: 171px;
  height: 47px;
  line-height: 47px;
  background: #3a7ce8;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  border-radius: 6px;
}
</style>
