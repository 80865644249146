<template>
  <div>
    <Verification Title="修改密码" v-model:show="registertype" width="650px">
      <template #neirong>
        <a-spin :spinning="spinning" tip="加载中...">
          <a-form
              :model="register"
              @finish="registeronFinish"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span:16}"
          >
            <a-form-item
                label="手机号码"
                name="phone"
                :rules="[{ required: true, validator: phonerule }]"
                placeholder="请输入手机号"
            >
              <a-input v-model:value="register.phone" placeholder="请输入手机号"/>
            </a-form-item>
            <a-form-item
                label="手机验证码"
                name="phoneCode"
                :rules="[{ required: true, message: '请输入验证码!' }]"
                placeholder="请输入验证码"
            >
              <div class="phoneCode">
                <div class="phoneCode_l">
                  <a-input v-model:value="register.phoneCode" placeholder="请输入验证码"/>
                </div>
                <div class="phoneCode_r" @click="sendPhoneCode">{{ btnText }}</div>
              </div>
            </a-form-item>
            <a-form-item
                label="新密码"
                name="pwd"
                :rules="[{ required: true, message: '请输入密码!' }]"
            >
              <a-input-password v-model:value="register.pwd" placeholder="请输入新密码"/>
            </a-form-item>
            <a-form-item
                label="确认新密码"
                name="pwdc"
                :rules="[{ required: true,validator: validatePass2}]"
            >
              <a-input-password v-model:value="register.pwdc" placeholder="请输入确认新密码"/>
            </a-form-item>
            <!-- <a-form-item :wrapper-col="{ span: 14, offset: 8 }"> -->
              <a-row>
                <a-col :span='22'>
                  <div class="fg_pwd_button">
                    <a-button class="fg_qx"  @click="registertype=false" >取消</a-button>
                    <a-button class="fg_qd" style="margin-left: 15px;" type="primary" html-type="submit" size="large">修改</a-button>
                  </div>
                </a-col>
              </a-row>
              
            <!-- </a-form-item> -->
          </a-form>
        </a-spin>
      </template>
    </Verification>
  </div>
</template>

<script>
import {Verification} from '@/components/index'

import { reactive, toRefs } from '@vue/reactivity'
import publicFn from '@/util/publicFn'
import {message} from 'ant-design-vue';
export default {
  name:'Password_index',
  components:{
    Verification
  },
  setup() {
    let data = reactive({
      //弹框控制
      registertype: false,
      spinning: false,
      //点击弹框
      registershow() {
        data.registertype = true;
      },
      // 表单
      register:{},
      // 提交
      registeronFinish:(val)=>{
        data.spinning=true;
        publicFn.post({
          serviceCode: "updateUserPwd",
          param: {
            ...val
          }
        }).then((res) => {
          data.spinning=false;
          message.success(res.message);
          data.registertype=false;
        }).catch(()=>{
          data.spinning=false;
          data.registertype=false;
        })
      },
      isClick: false,
      currentTime: 60,
      btnText: '发送验证码',
      //  点击发送验证码
      //发送验证码
      sendPhoneCode() {
        if (data.isClick) {
          return false;
        }
        //验证手机号
        if (!data.yzphone()) {
          return false;
        }
        data.isClick = true;
        let currentTime = data.currentTime;
        publicFn.post({
          serviceCode: "sendPhoneCode",
          param: {
            phone: data.register.phone,
            type: 2
          }
        }).then((res) => {
          let timeInterval = setInterval(function () {
            currentTime--;//倒计时
            data.btnText = currentTime + '';
            if (currentTime <= 0) {
              clearInterval(timeInterval)//重置倒计时
              data.btnText = '发送验证码';
              data.currentTime = 60;
              data.isClick = false;
            }
          }, 1000);
          message.success(res.message);
        }).catch(()=>{
          data.isClick = false;
        })
      },
      //手机号正则
      phonerule: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入手机号');
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {

          return Promise.reject("手机号格式不正确!");
        } else {
          return Promise.resolve();
        }
      },
      //发送验证码验证手机号
      yzphone() {
        if (data.register.phone === '') {
          message.error('请输入手机号');
          return false
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(data.register.phone)) {
          message.error('手机号格式不正确');
          return false
        } else {
          return true;
        }
      },
      //确认密码正则
      validatePass2: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入密码');
        } else if (value !== data.register.pwd) {
          return Promise.reject("两次密码不一致!");
        } else {
          return Promise.resolve();
        }
      },
    })


    return {
      ...toRefs(data)
    }
  }
}
</script>

<style scoped>
.phoneCode {
  display: flex;
  justify-content: space-between;
}

.phoneCode_l {
  width: calc(100% - 100px);
}

.phoneCode_r {
  width: 100px;
  background: #40a9ff;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  height: 32px;
}
.fg_pwd_button {
  text-align: right;
}
.fg_qx {
  width: 100px;
  padding: 0px 20px;
  height: 45px;
  line-height: 40px;
  font-size: 18px;
}
.fg_qd {
  width: 100px;
  padding: 0px 20px;
  height: 45px;
  line-height: 40px;
  font-size: 18px;
}
</style>