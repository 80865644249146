<template>
  <div class="ZjLiconimg">
    <div class="jiaofei">
      <!-- 移入 -->
      <div
        class="zc-box"
        style="position: relative"
        @mouseleave="mouseLeave(0)"
        @mouseenter="fnMouseenter(0)"
      >
        <img class="imss" src="../../assets/img/zhicehnghong.png" alt="" />
        <div
          class="tl-awning-window-we"
          v-show="zhiceShow"
          style="right: 130px !important"
        >
          <template v-if="professionalTitleConsultation">
            <div class="no-bg we-text-title">
              {{ professionalTitleConsultation.itemName || "-" }}
            </div>
            <div class="no-bg">
              <a-image
                :width="200"
                :src="professionalTitleConsultation.itemPath"
              />
            </div>
            <div
              class="we-text-footer"
              v-if="professionalTitleConsultation.itemValue"
            >
              {{ professionalTitleConsultation.itemValue }}
            </div>
          </template>
        </div>
      </div>
      <div class="logbox logbox-img-gouwuche">
        <a-badge :count="cartTotal" :overflow-count="999">
          <div
            @mouseover="mouseLeave(1)"
            class="logbox-img"
            @click="opencartf"
            :class="gouwcheShow ? 'selected-style' : ''"
          >
            <span
              :class="gouwcheShow ? 'selected-style' : ''"
              class="iconfont icon-gouwuchefill icon-size gouwuche"
            ></span>
          </div>
        </a-badge>
        <div class="logbox-text no-bg-text">购物车</div>
      </div>
      <div
        class="logbox"
        @mouseleave="mouseLeave(2)"
        @mouseenter="fnMouseenter(2)"
      >
        <div
          class="logbox-img"
          :class="tlaWaningwindowshow ? 'selected-style' : ''"
        >
          <span
            :class="tlaWaningwindowshow ? 'selected-style' : ''"
            class="iconfont icon-huafei icon-size"
          ></span>
        </div>
        <div class="logbox-text">客服电话</div>
        <div class="tl-awning-window" v-show="tlaWaningwindowshow">
          <div>客服电话：{{ operatorPhone }}</div>
          <div>工作时间：工作日</div>
          <div>上午：8:30 - 12:00</div>
          <div>下午：13:30 - 18:00</div>
        </div>
      </div>
      <div
        class="logbox"
        @mouseleave="mouseLeave(3)"
        @mouseenter="fnMouseenter(3)"

      >
        <!--        selected-style-->
        <div class="logbox-img" :class="weixinShow ? 'selected-style' : ''">
          <span
            :class="weixinShow ? 'selected-style' : ''"
            class="iconfont icon-weixin icon-size"
          ></span>
        </div>
        <div class="logbox-text">{{weCom.itemName}}</div>
        <div class="tl-awning-window-we" v-show="weixinShow">
          <div class="no-bg we-text-title">{{ weCom.itemName || "-" }}</div>
          <div class="no-bg  no-bg-tip" >
<!--            style="font-size: 18px;width: 200px;height: 150px;display: flex;align-items: center;justify-content: center !important; text-align: center !important;"-->
<!--            企业微信暂停使用-->
            <a-image :width="200" :src="weCom.itemPath" />
          </div>
        </div>
      </div>
      <div
        class="logbox logbox-img-div"
        style="text-align: center; cursor: pointer"
        @mouseover="mouseLeave"
      >
        <!-- 去顶部 -->
        <img
          class="log_img"
          src="../../assets/img/zhiding.png"
          alt=""
          @click="toTop"
        />
      </div>
    </div>
    <pay ref="payxx" @Payment="fnGoabck" />
    <Verification Title="在线客服" v-model:show="Contacttype" width="600px">
      <template #neirong>
        <div class="web-chat-page">
          <iframe
            id="web-chat-iframe"
            height="100%"
            width="100%"
            frameborder="0"
            :src="iframeUrl"
          >
          </iframe>
        </div>
      </template>
    </Verification>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity"
import { pay } from "@/components/payorder"
import publicFn from "@/util/publicFn"
import { useStore } from "vuex"
import { watch } from "@vue/runtime-core"
import Verification from "@/components/Verification"
export default {
  name: "ZjLiconimg",
  components: {
    pay,
    Verification,
  },
  setup() {
    const store = useStore()
    let data = reactive({
      operatorPhone: store.state.layou.Displays.operatorPhone.itemValue,
      weCom: store.state.layou.Displays?.weCom,
      professionalTitleConsultation: {},
      tlaWaningwindowshow: false,
      weixinShow: false,
      gouwcheShow: false,
      zhiceShow: false,
      iframeUrl: "",
      //控制客服变量
      Contacttype: false,
      cartTotal: store.state.cartTotal,
      contacttype: false,
      fnMouseenter(num) {
        switch (num) {
          case 0:
            data.professionalTitleConsultation =
              store.state.layou.Displays?.professionalTitleConsultation
            data.zhiceShow = true
            break
          case 1:
            break
          case 2: //客服电话
            data.tlaWaningwindowshow = true
            break
          case 3: //企业微信
            data.weixinShow = true
            break
        }
      },
      mouseLeave(num) {
        switch (num) {
          case 0:
            data.zhiceShow = false
            break
          case 1:
            break
          case 2: //客服电话
            data.tlaWaningwindowshow = false
            break
          case 3: //企业微信
            data.weixinShow = false
            break
        }
      },
      //联系方式鼠标移入
      mouseOver() {},
      // 获取联系方式
      contact: store.state.layou.Displays.contact.itemValue || "联系我们",
      Displays: store.state.layou.Displays,
      //跳转路由
      locationHref: publicFn.routeJump,
      reightcontrol: true, //控制显示与隐藏
      distance: 0,
      // 获取滚动到顶部距离
      getScrollTop: () => {
        var scrollPos
        if (window.pageYOffset) {
          scrollPos = window.pageYOffset
        } else if (document.compatMode && document.compatMode != "BackCompat") {
          scrollPos = document.documentElement.scrollTop
        } else if (document.body) {
          scrollPos = document.body.scrollTop
        }
        return scrollPos
      },
      // 去顶部
      toTop() {
        document.documentElement.scrollTop = 0
      },
      payxx: null, //购物车
      //打开购物车
      opencartf() {
        data.payxx.opencart()
        data.gouwcheShow = true
      },
      fnGoabck() {
        setTimeout(() => {
          data.gouwcheShow = !data.gouwcheShow
        }, 500)
      },
      // logc存储
      Setlocal(key, value, sTime) {
        let obj = {
          data: value,
          time: Date.now(),
          storageTime: sTime,
        }
        localStorage.setItem(key, JSON.stringify(obj))
      },
      // 取值
      Getlocal(key) {
        let obj = localStorage.getItem(key)
        obj = JSON.parse(obj)
        if (Date.now() - obj.time > obj.storageTime) {
          localStorage.removeItem(key)
          return null
        }
        return obj.data
      },
    })
    // 监听滚动距离顶部的距离，弹框是否展示
    window.onscroll = function () {
      data.distance = data.getScrollTop()
    }
    if (!localStorage.getItem("reightcontrol")) {
      data.reightcontrol = true
    } else if (data.Getlocal("reightcontrol")) {
      data.reightcontrol = true
    } else {
      data.reightcontrol = false
    }
    watch(
      () => store.state.layou.Displays.contact.itemValue,
      (newl) => {
        data.contact = newl
      }
    )
    watch(
      () => store.state.layou.Displays.workTime.itemValue,
      (newl) => {
        console.log(newl)
      }
    )
    return {
      ...toRefs(data),
    }
  },
}
</script>

<style scoped>
.no-bg-text:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.we-text-footer {
  text-align: center;
  margin-top: 10px;
}
.no-bg:hover {
  background-color: #ffffff !important;
}
.tl-awning-window > div {
  line-height: 30px;
  cursor: pointer;
}
.we-text-title {
  text-align: center !important;
}

.tl-awning-window :nth-child(1),
.we-text-title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #0059e8;
}
.tl-awning-window-we :nth-child(2):hover {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.tl-awning-window-we:hover {
  background-color: #ffffff !important;
}
.tl-awning-window,
.tl-awning-window-we {
  position: absolute;
  top: 0px;
  right: 120px;
  z-index: 100;
  width: 294px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 0px #dbdbdb;
  border-radius: 6px;
  text-align: left !important;
  padding: 20px 30px;
}
.icon-size,
.logbox-img-gouwuche span {
  color: #0059e8;
  font-size: 30px;
}
.logbox-img-gouwuche :hover {
  background-color: #0059e8 !important;
  .gouwuche {
    color: #ffffff !important;
  }
}
.no-bg-text:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.selected-style {
  color: #ffffff !important;
  background-color: #0059e8 !important;
}
.logbox-img-div {
  width: 50px !important;
  background-color: #ffffff;
  padding: 10px 5px !important;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 2px rgba(103, 100, 100, 0.5);
}
.tl-awning-window,
.tl-awning-window > div:hover {
  background-color: #ffffff !important;
}
.web-chat-page {
  width: 100%;
}

#web-chat-iframe {
  width: 100%;
  height: 600px;
}

.ZjLiconimg {
  z-index: 500;
  position: relative;
  position: fixed;
  right: 17px;
  top: 34%;
  width: 100px;
  height: 200px;
}

.jiaofei {
  position: fixed;
  right: 20px;
  z-index: 999;
  transform: translateY(-32%);
}

.shrink {
  position: fixed;
  /* bottom: 18%; */
  z-index: 500;
  right: 30px;
  z-index: 999;
  transform: translateY(179%);
}

.imss {
  width: 125px;
  /*width: 160px;*/
  cursor: pointer;
}
.zc-box {
  position: relative;
  width: 165px;
  position: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.logbox-text {
  margin-top: 10px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  cursor: pointer;
  color: #333333;
}
.log_img:hover {
  background-color: #ffffff !important;
}
.logbox {
  margin: 15px auto;
  text-align: center;
  width: 134px;
  position: relative;
  padding: 0 30px;
}
.logbox-img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 2px rgba(103, 100, 100, 0.5);
  cursor: pointer;
}
.log_img {
  width: 38px;
  height: 38px;
}
@keyframes messer {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(50px, 500px);
  }
  40% {
    transform: translate(200px, 600px);
  }
  60% {
    transform: translate(600px, 600px);
  }
  80% {
    transform: translate(300px, 200px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.youlog {
  width: 29px;
  height: 29px;
  margin-top: 5px;
}

.shouqiicon {
  width: 130px;
  /*height: 137px;*/
  position: absolute;
  right: -40px;
  top: -110px;
}
.zhiding {
  background: #fff;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  position: absolute;
  margin-bottom: 12px;
  margin-top: 13px;
  padding: 20px 10px;
  border-radius: 10px;
}
.technolo_div > div {
  width: 80px;
  text-align: center;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #442d20;
}
.technolo_img img {
  width: 50px;
  height: 50px;
}
</style>
