// import { Skeleton } from 'ant-design-vue'
import Advertisement from './Advertisement'
import Area from './Area/index.vue'
import Zreaindex from './Area/areaindex.vue'
import Crumbs from './Crumbs'
import Display from './Display'
import Liconimg from './Liconimg'
import Liconimgs from './Liconimg/index_1.vue'
import Tail from './Tail'
import Topnav from './TopNav/index.vue'
import Navbar from './TopNav/Navbar.vue'
import Notification from './Notification/index.vue'
import Centerlabel from './Centerlabel/index.vue'
import Verification from './Verification/index.vue'
import Signin from './Signin/index.vue'
import payment from './payment/index.vue'
import Information from './Information/index.vue'
import Stable from './Stable/index.vue'
import BulletFrame from './Bulletframe'
import Category from './Category/index.vue'
import formcategory_index from './Category/formcategory.vue'
import screen from './screen/formcategory.vue'
import extension from './extension/index.vue'
import commodity from "@/components/Area/commodity"
import Password from "@/components/Password"
import Doubt from "@/components/Doubt/index.vue"
import HzTabs from './hzTabs/hzTabsInfo.vue'
export {
  Liconimgs,
  Advertisement,
  extension,
  formcategory_index,
  screen,
  Area,
  Zreaindex,
  Crumbs,
  Display,
  Liconimg,
  Tail,
  Topnav,
  Navbar,
  Notification,
  Centerlabel,
  Verification,
  Signin,
  payment,
  Information,
  Stable,
  BulletFrame,
  Category,
  commodity,
  Password,
  Doubt,
  HzTabs
  }
export default {
  install(app) {
    // app.component(Advertisement.name, Advertisement)
    app.component(Area.name, Area)
    app.component(Zreaindex.name, Zreaindex)
    app.component(Crumbs.name, Crumbs)
    app.component(Display.name, Display)
    // app.component(Liconimg.name, Liconimg)
    app.component(Tail.name, Tail)
    // app.component(Topnav.name, Topnav)
    // app.component(Navbar.name, Navbar)
    app.component(Notification.name, Notification)
    // app.component(Centerlabel.name, Centerlabel)
    // app.component(Verification.name, Verification)
    app.component(Signin.name, Signin)
  }
}