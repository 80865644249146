import router from "@/router";
import http from '@/util/http.js'
import store from '@/store'
import {Modal} from "ant-design-vue";

const publicFn = {
    //http请求
    post:http.post,
    //跳转路由
    routeJump(item){
        router.push(item)
    },
    //日期格式化
    timeformat(val){
        if(val){
            return val.split('T')[0]
        }else {
            return ''
        }
    },
    //通知公告
    getAnnouncementPageList(id,page){
        return http.post({
            serviceCode: "getAnnouncementPageList",
            param: {
                articleTitle:id
            },
            "page": page
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //职称政策
    getInformationPageList(articleType,page){
        return http.post({
            serviceCode: "getInformationPageList",
            param: {
                articleType:articleType
            },
            "page": page
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    getPanyNewsPageList(id,page){
        return http.post({
            serviceCode: "getInformationPageList",
            param: {
                articleType:'panyNews'
            },
            "page": page
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //获取菜单
    getPlatformMenu(param){
        return http.post({
            serviceCode: "getPlatformMenu",
            param: param
        }).then((res) => {
            let type=false;
            let ChangingCareerstype = false;
            res.rows.map((v)=>{
                if(v.menuPath=='Notice'){
                    type=true;
                }
                if(v.menuPath=='ChangingCareers'){
                    ChangingCareerstype=true;
                }
            });
           store.commit('setPublicDemand',type)
           store.commit('setChangingCareers',ChangingCareerstype)
           store.commit('setHeadList',res.rows)
        }).catch(()=>{
            return {}
        })
    },
    //安徽获取用户token
    getAhUserInfo(){
        let href=window.location.href;
        let token=href.split('studentInfoKey=')[1];
        // let token="21521dbb8c9543ab92a4b61af23d6b07";
        if(token){
            return http.post({
                serviceCode: "getAhUserInfo",
                param: {
                    ahToken:token
                }
            }).then((res) => {
                localStorage.setItem('userinfo', JSON.stringify(res))
                store.commit('layou/setUserinfo',res)
            }).catch(()=>{
                return {}
            })
        }

    },
    //甘肃获取用户token
    getGsUserInfo(){
        let href=window.location.href;
        let token=href.split('studentId=')[1];
        // let token="21521dbb8c9543ab92a4b61af23d6b07";
        if(token){
            return http.post({
                serviceCode: "getGsUserInfo",
                param: {
                    studentId:token
                }
            }).then((res) => {
                localStorage.setItem('userinfo', JSON.stringify(res))
                store.commit('layou/setUserinfo',res)
            }).catch(()=>{
                return {}
            })
        }

    },
    //菏泽获取用户token
    getHzUserInfo(){
        let href=window.location.href;
        let token=href.split('token=')[1]
        if(token){
            return http.post({
                serviceCode: "getTokenWithNoLogin",
                param: {
                  token:token
                }
            }).then((res) => {
                localStorage.setItem('userinfo', JSON.stringify(res))
                store.commit('layou/setUserinfo',res)
            }).catch(()=>{
                return {}
            })
        }

    },
    //获取平台名称
    getPlatformInfo(){
        return http.post({
            serviceCode: "getPlatformInfo",
            param: {
            }
        }).then((res) => {
            let obj={
            }
            res.rows.map((v)=>{
                obj[v.itemType]=v;
            })
            store.commit('layou/setDisplays',obj)
           store.commit('setPlatformName',res.platformDesc)
           store.commit('setProjectinfo',res)

        }).catch(()=>{
            return {}
        })
    },
    // 安徽用户是否报名
    getAHUserSignUp(param){
        return http.post({
            serviceCode: "getAHUserSignUp",
            ...param
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    // 甘肃用户是否报名
    getGsSignUpInfoStatus(param){
        return http.post({
            serviceCode: "getGsSignUpInfoStatus",
            ...param
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //获取详情下面的推荐列表
    getRecommendCourseAList(param){
        return http.post({
            serviceCode: "getRecommendCourseList",
            param: param
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //删除购物车
    delcar(courseWrapperId){
        return http.post({
            serviceCode: "delMyCart",
            param: {
                courseWrapperIds:courseWrapperId
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //获取购物车列表
    getMyCartList(){
        return http.post({
            serviceCode: "getMyCartList",
            param: {
            },
            page:{
                "currentPage": 1,
                "showCount": 999
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    getPayMode(){
        return http.post({
            serviceCode: "getPayMode",
            param: {
            },
            page:{
                "currentPage": 1,
                "showCount": 999
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //添加购物车
    addMyCart(courseWrapperId){
        return http.post({
            serviceCode: "addMyCart",
            param: {
                courseWrapperId:courseWrapperId
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //获取购物车数量
    getMyCartTotal(){
        return http.post({
            serviceCode: "getMyCartTotal",
            param: {
            }
        }).then((res) => {
            store.commit('setCartTotal',res.rows[0].cartTotal)
        }).catch(()=>{
            return {}
        })
    },
    //微信支付
    wxPayNative(courseWrapperIds){
        return http.post({
            serviceCode: "wxPayNative",
            param: {
                courseWrapperIds:courseWrapperIds
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    // 马鞍山支付提示
    getBussinessDictItemLists(){
        return http.post({
            serviceCode: "getBussinessDictItemList",
            param: {
                dictCode:'payPrompt'
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //支付宝支付
    aliPayPc(courseWrapperIds){
        return http.post({
            serviceCode: "aliPayPc",
            param: {
                courseWrapperIds:courseWrapperIds
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //查询支付状态
    getPaymentStatus(orderNo){
        return http.post({
            serviceCode: "getPaymentStatus",
            param: {
                orderNo:orderNo
            }
        }).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    //查询购物车结算金额
    getSettlementCourseWrapperDetailList(courseWrapperIds){
        return http.post({
            serviceCode: "getMyCartToPayList",
            param: {
                courseWrapperIds:courseWrapperIds
            }
        }).then((res) => {
           return res
        }).catch(()=>{
            return {}
        })
    },
    // 获取滚动到顶部距离
    getScrollTop:()=> {
        var scrollPos;
        if (window.pageYOffset)
        {
            scrollPos = window.pageYOffset;
        }
        else if (document.compatMode && document.compatMode != 'BackCompat')
        {
            scrollPos = document.documentElement.scrollTop;
        }
        else if (document.body)
        {
            scrollPos = document.body.scrollTop;
        }
        return scrollPos;
    },
    //推送学时
    upPlayChapter:(param)=> {
        return http.post({
            serviceCode: "upPlayChapter",
            param:param
        }).then((res) => {
            if(res.code==400){
                Modal.confirm({
                    title: '提示',
                    content: () =>
                        <div v-html={res.message}/>
                    ,
                    okText: '确定',
                    cancelText: '取消',

                },);
            }
            return res
        }).catch(()=>{
            return {}
        })
    },
    upPlayChapter1:(param)=> {
        return http.post({
            serviceCode: "upPlayChapterV3",
            param:param
        }).then((res) => {
            if(res.code==400){
                Modal.confirm({
                    title: '提示',
                    content: () =>
                        <div v-html={res.message}/>
                    ,
                    okText: '确定',
                    cancelText: '取消',

                },);
            }
            return res
        }).catch(()=>{
            return {}
        })
    },
    //同步请求
    asyncPost:(param)=> {
        return http.post(param).then((res) => {
            return res
        }).catch(()=>{
            return {}
        })
    },
    // 获取当前年月日
    getDateTime:(e)=>{
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = (month > 9) ? month : ("0" + month);
        day = (day < 10) ? ("0" + day) : day;
        if(e=='noline'){
            return  `${year}${month}${day}`;
        }else{
            return  `${year}-${month}-${day}`;
        }
    },
    // 单课程支付结算信息
    getCourseWrapperSettlementInfo:(param)=> {
      return http.post({
          serviceCode: "getCourseWrapperSettlementInfo",
          param:param
      }).then((res) => {
          return res
      }).catch(()=>{
          return {}
      })
  },
}
export default publicFn;