<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
dayjs.locale('zh-cn')
export default {
  name: 'app',
  components: {},
  setup() {
    const store = useStore()
    const type = getBrowserInfo()
    const bodyScale = () => {
      let devicewidth = document.documentElement.clientWidth //获取当前分辨率下的可是区域宽度
      let scale = devicewidth / 1650 // 分母——设计稿的尺寸
      if (type == 'chrome') {
        document.body.style.zoom = scale //放大缩小相应倍数
      }
    }
    function getBrowserInfo() {
      var ua = window.navigator.userAgent.toLocaleLowerCase()
      var browserType = null
      if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
        browserType = 'IE'
      } else if (ua.match(/firefox/) != null) {
        browserType = '火狐'
      } else if (ua.match(/ubrowser/) != null) {
        browserType = 'UC'
      } else if (ua.match(/opera/) != null) {
        browserType = '欧朋'
      } else if (ua.match(/bidubrowser/) != null) {
        browserType = '百度'
      } else if (ua.match(/metasr/) != null) {
        browserType = '搜狗'
      } else if (
        ua.match(/tencenttraveler/) != null ||
        ua.match(/qqbrowse/) != null
      ) {
        browserType = 'QQ'
      } else if (ua.match(/maxthon/) != null) {
        browserType = '遨游'
      } else if (ua.match(/chrome/) != null) {
        var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')

        if (is360) {
          browserType = '360'
        } else {
          browserType = 'chrome'
        }
      } else if (ua.match(/safari/) != null) {
        browserType = 'Safari'
      }
      function _mime(option, value) {
        var mimeTypes = navigator.mimeTypes
        for (var mt in mimeTypes) {
          if (mimeTypes[mt][option] == value) {
            return true
          }
        }
        return false
      }
      return browserType
    }
    onMounted(() => {
      if(!['ta','lc'].some(item=>{return item==store.state.urlAh})){
        bodyScale()
      }
      window.addEventListener('resize', function () {
        if(!['ta','lc'].some(item=>{return item==store.state.urlAh})){
          bodyScale()
        }
      })
    })
    return {
      locale: zhCN,
      //  bodyScale
    }
  },
}
</script>

<style>
.ant-carousel .slick-slide {
  background: #fff !important;
}
body {
  min-width: 1520px !important;
}
.widthcontent {
  width: 1400px !important;
  margin: 0 auto;
}

@media screen and (max-width: 755px) {
  /*.widthcontent {*/
  /*  width: auto !important;*/
  /*}*/
  /*#app{*/
  /*  position: absolute;*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*  left: 0;*/
  /*  top: 0;*/
  /*}*/
  /*html{*/
  /*  position: fixed;*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*  left: 0;*/
  /*  top: 0;*/
  /*}*/
}
.mymessage {
  font-size: 20px;
}
.ant-message .anticon {
  font-size: 20px !important;
}
.box_txt {
  left: 0 !important;
  margin-top: 50px !important;
}
html {
  overflow-y: overlay;

  /*-webkit-filter: grayscale(100%);*/
  /*-moz-filter: grayscale(100%);*/
  /*-ms-filter: grayscale(100%);*/
  /*-o-filter: grayscale(100%);*/
  /*filter: grayscale(100%);*/
  /*filter: progid:DXImageTransform.Miscrosoft.BasicImage(grayscale = 1);*/
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
