<template>
  <div class="box_t" :class="hzShow ? 'hzbox_t' : ''">
    <div class="boxtt" v-if="!userinfo">
      <div class="hydl">欢迎登录</div>
      <div v-if="sdjypxShow">
        <div  style="min-height: 300px; padding-top: 100px">
          <a-button class="primarybutt" @click="fnCeshilinktogl()" type="primary" size="large">{{loginName}}</a-button>
        </div>
      </div>
      <div v-if="!sdjypxShow">
        <div v-if="$store.state.urlAh == 'ah'" style="min-height: 300px; padding-top: 100px">
          <a-button class="primarybutt" @click="linktogl('ah')" type="primary" size="large">安徽政务中心</a-button>
        </div>
        <div v-else-if="$store.state.urlAh == 'hz'" style="min-height: 300px; padding-top: 100px">
          <a-button class="primarybutt" @click="linktogl($store.state.urlAh == 'zyx'?'zyx':'hz')" type="primary" size="large">{{$store.state.urlAh == 'zyx'?'安徽政务服务网登录':'菏泽市继续教育管理平台'}}</a-button>
        </div>
        <div v-else-if="$store.state.urlAh == 'gs'" style="min-height: 300px; padding-top: 100px">
          <a-button class="primarybutt" @click="linktogl('gs')" type="primary" size="large">甘肃政务中心</a-button>
        </div>
        <div v-else-if="$store.state.urlAh == 'hz1'" style="padding-top: 100px" :style="hzShow ? { width: '270px' } : { width: auto }">
          <a-button class="primarybutt" @click="linktogl('hz1')" type="primary" size="large">菏泽市继续教育管理平台</a-button>
        </div>
        <div v-else>
          <div class="input_box">
            <a-form :model="formState" @finish="onFinish" @finishFailed="onFinishFailed">
              <a-form-item name="username" :class="$store.state.HzType ? 'mab15' : ''"
                           :rules="[
                {
                  required: true,
                  message: $store.state.HzType? '身份证号': '请输入手机号/身份证号',
                },
              ]"
                           :placeholder=" $store.state.HzType ? '身份证号' : '请输入手机号/身份证号' "
              >
                <div class="input_box_li">
                  <a-input
                      class="input_li"
                      v-model:value="formState.username"
                      :placeholder="
                    $store.state.HzType ? '身份证号' : '请输入手机号/身份证号'
                  "
                  />
                </div>
              </a-form-item>
              <a-form-item
                  name="password"
                  :class="$store.state.HzType ? 'mab15' : ''"
                  :rules="[{ required: true, message: '请输入密码!' }]"
                  :placeholder="$store.state.urlAh == 'zyx'?'默认身份证后六位':'请输入密码'"
              >
                <div class="input_box_li">
                  <a-input-password
                      class="input_li"
                      v-model:value="formState.password"
                      :placeholder="$store.state.urlAh == 'zyx'?'默认身份证后六位':'请输入密码'"
                  />
                </div>
                <!-- <a-input-password v-model:value="formState.password" /> -->
              </a-form-item>
              <a-form-item
                  v-if="$store.state.HzType"
                  name="captcha"
                  :class="$store.state.HzType ? 'mab15' : ''"
                  :rules="[{ required: true, message: '请输入验证码!' }]"
                  placeholder="请输入验证码"
              >
                <div class="input_box_li" style="display: flex">
                  <a-input
                      class="input_li"
                      v-model:value="formState.captcha"
                      placeholder="请输入验证码"
                  />
                  <span><img @click="getyzm" :src="imgyzm" alt="" /></span>
                </div>
              </a-form-item>

              <a-form-item>
                <a-button
                    class="primarybutt"
                    type="primary"
                    html-type="submit"
                    size="large"
                >登录</a-button
                >
                <!-- <div class="butt_l" @click="onFinish">登录</div> -->
              </a-form-item>
            </a-form>
            <!-- <div class="input_box_li"><a-input  class="input_li" v-model:value="username" placeholder="请输入手机号" /></div>
            <div class="input_box_li"><a-input  class="input_li" v-model:value="password" placeholder="请输入密码" /></div>
           <div class="butt_l" @click="onFinish">登录</div> -->
          </div>
          <div class="span_ll">
            <span class="sp_l" @click="registershow">没有账号，先注册</span>
            <span class="sp_s" v-if="!$store.state.HzType" @click="forgot"
            >忘记密码</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="boxtt boxttb"
      v-else
      :style="hzShow ? { width: '320px' } : { width: auto }"
    >
      <div class="b_cg_h" :style="hzShow ? { 'text-aline': 'center' } : {}">
        欢迎回来，{{ userinfo.userInfo.realName }}
      </div>
      <div class="b_cg_da_box">
        <img
          class="b_cg_ewm"
          v-if="getavatar && getavatar !== 'undefined'"
          :src="getavatar"
          alt=""
        />
        <img
          class="b_cg_ewm"
          v-else
          src="../../assets/img/head.png"
          alt=""
        />
      </div>
      <div class="b_cg_box_zx">
        <div class="b_cg_zx" @click="toZhongxin">进入个人中心</div>
      </div>
    </div>
    <Verification Title="注册" v-model:show="registertype" width="650px">
      <template #neirong>
        <a-form
          :model="register"
          @finish="registeronFinish"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-form-item
            label="手机号码"
            name="phone"
            :rules="[{ required: true, validator: phonerule }]"
            placeholder="请输入手机号"
          >
            <a-input
              v-model:value="register.phone"
              placeholder="请输入手机号"
            />
          </a-form-item>
          <a-form-item
            label="手机验证码"
            name="phoneCode"
            :rules="[{ required: true, message: '请输入验证码!' }]"
            placeholder="请输入验证码"
          >
            <div class="phoneCode">
              <div class="phoneCode_l">
                <a-input
                  v-model:value="register.phoneCode"
                  placeholder="请输入验证码"
                />
              </div>
              <div class="phoneCode_r" @click="sendPhoneCode">
                {{ btnText }}
              </div>
            </div>
          </a-form-item>
          <a-form-item
            label="所属区域"
            name="qy"
            :rules="[{ required: true, message: '请输入所属区域!' }]"
            placeholder="请输入所属区域"
          >
            <cascader
              v-model:modelValue="register.qy"
              placeholder="请输入所属区域"
            ></cascader>
          </a-form-item>
          <a-form-item  name="companyName" label="单位名称"  placeholder="请输入单位名称">
            <a-input v-model:value="register.companyName" placeholder="请输入单位名称"/>
          </a-form-item>
          <a-form-item
            label="密码"
            name="pwd"
            :rules="[{ required: true, message: '请输入密码!' }]"
          >
            <a-input-password
              v-model:value="register.pwd"
              placeholder="请输入密码"
            />
          </a-form-item>
          <a-form-item
            label="确认密码"
            name="pwdc"
            :rules="[{ required: true, validator: validatePass2 }]"
          >
            <a-input-password
              v-model:value="register.pwdc"
              placeholder="请输入密码"
            />
          </a-form-item>
          <a-form-item
            label="姓名"
            name="realName"
            :rules="[{ required: true, message: '请输入姓名!' }]"
          >
            <a-input
              v-model:value="register.realName"
              placeholder="请输入姓名"
            />
          </a-form-item>
          <a-form-item
            label="身份证号"
            name="identityNo"
            :rules="[{ required: true, validator: phoneNumberCheck }]"
            placeholder="请输入手机号"
          >
            <a-input
              v-model:value="register.identityNo"
              placeholder="请输入身份证号"
            />
          </a-form-item>
          <!--          仅泰安聊城烟台有专业选修-->
          <template v-if="['ta','lc','yt'].some(item=>{return item=== $store.state.urlAh})&&technicalJobculum&&technicalJobculum.length!=0">
            <a-form-item label="专业技术职称系列" >
              <a-select
                  v-model:value="register.technicalJob"
                  :fieldNames="{ label: 'itemName', value: 'itemValue' }"
                  style="width: 100%"
                  placeholder="请选择专业技术职称系列"
                  :options="technicalJobculum"
              />
            </a-form-item>
          </template>
          <a-form-item :wrapper-col="{ span: 16, offset: 6 }">
            <div class="fg_pwd_buttonbox">
              <a-button
                class="fg_pwd_btn_l"
                @click="registertype = false"
                size="large"
                >取消</a-button
              >
              <a-button
                class="fg_pwd_btn_l"
                style="margin-left: 15px"
                type="primary"
                html-type="submit"
                size="large"
                >注册</a-button
              >
            </div>
          </a-form-item>

        </a-form>
      </template>
    </Verification>
    <forgotpassword ref="fgfwd"></forgotpassword>
    <div v-if="towCode" class="towCode">
      <img class="picurlStyle" v-if="picurl" :src="picurl" alt="" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from '@vue/reactivity'
import { Verification } from '@/components/index'
import cascader from './cascader.vue'
import publicFn from '@/util/publicFn'
import { message } from 'ant-design-vue'
import http from '@/util/http.js'
import { useStore } from 'vuex'
import {onMounted, watch} from '@vue/runtime-core'
import forgotpassword from './forgotpassword.vue'
import  utils  from '@/util/utilsIndex'
export default {
  name: 'ZjSignin',
  components: {
    Verification,
    cascader,
    forgotpassword,
  },
  setup() {
    let store = useStore()
    let hzShow = false
    let pages = store.state.urlAh
    if (pages == 'hz1') {
      hzShow = true
    }
    let data = reactive({
      sdjypxShow:window.location.href.indexOf('sdjypx')!==-1?true:false,//true:测试环境，false:正式环境
      loginName:utils.loginName()||'',
      technicalJobculum:[],
      ZyxBtnloading: false,
      userinfo: store.state.layou.userinfo,
      isClick: false,
      currentTime: 60,
      btnText: '发送验证码',
      //  点击发送验证码
      //注册表单
      register: {
        identityType: 1,
      },
      //注册弹框控制
      registertype: false,
      // 获取专业列表
      fnTechnicalJobculum(){
        publicFn
            .post({
              serviceCode: 'getCourseScreen',
              param: {
                dictCode: 'professionalSeries',
              },
            })
            .then((res) => {
              data.technicalJobculum = res.rows
            })
      },
      // 众益学登录
      fnZyxlogin() {
        if (!data.formState.phonezyx) {
          return message.error('请输入手机号')
        }
        if (!data.formState.codezyx) {
          return message.error('请输入验证码')
        }
        data.ZyxBtnloading = true
        http
          .post({
            serviceCode: 'ahAppletUserLogin',
            param: {
              phone: data.formState.phonezyx,
              phoneCode: data.formState.codezyx,
            },
          })
          .then((res) => {
            data.ZyxBtnloading = false
            message.success('登录成功')
            data.userinfo = res
            localStorage.setItem('userinfo', JSON.stringify(res))
            store.commit('layou/setUserinfo', res)
            message.success(res.message)
            // 存储头像到本地
            store.commit('layou/setavatar', res.userInfo.avatar)
            data.getavatar = res.userInfo.avatar
          })
          .catch(() => {
            data.ZyxBtnloading = false
            // data.isClick = false
          })
      },

      // 获取验证码
      fnGetcode() {
        if (!data.formState.phonezyx) {
          return message.error('请输入手机号')
        }
        if (data.isClick) {
          return false
        }
        data.isClick = true
        let currentTime = data.currentTime
        http
          .post({
            serviceCode: 'sendPhoneCode',
            param: {
              phone: data.formState.phonezyx,
              type: 2,
            },
          })
          .then(() => {
            let timeInterval = setInterval(function () {
              currentTime-- //倒计时
              data.btnText = currentTime + ''
              if (currentTime <= 0) {
                clearInterval(timeInterval) //重置倒计时
                data.btnText = '发送验证码'
                data.currentTime = 60
                data.isClick = false
              }
            }, 1000)
            message.success('发送验证码成功')
          })
          .catch(() => {
            data.isClick = false
          })
      },
      // 测试环境跳转
      fnCeshilinktogl(){
        window.location.href = 'https://manage-test.sdjypx.cn/#/user/login'
      },
      //跳转政务服务中心
      linktogl(e) {
        // let obj={
        //     token: "53c87fe3c92e408a96f2de61645f8806",
        //      uid: "8dc529b8748e463d9f8711552f04ee57",
        // }
        // localStorage.setItem('userinfo',JSON.stringify(obj))
        // publicFn.routeJump({
        //   path:'/Personal'
        // })
        if (e == 'hz1') {
          window.location.href = 'http://mh.shunjy.com:8090/#/'
        } else if (e == 'zyx') {
          window.location.href = 'http://117.68.7.48:8081/index'
        } else if(e=='hz'){
          window.location.href = 'http://mh.shunjy.com:8090/#/'
        }else if(e=='gs'){
          window.location.href = 'https://manage-test.sdjypx.cn/#/user/login'
        }else{
          window.location.href = 'http://117.68.7.48:8081'
        }
      },
      //确认密码正则
      validatePass2: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入密码')
        } else if (value !== data.register.pwd) {
          return Promise.reject('两次密码不一致!')
        } else {
          return Promise.resolve()
        }
      },
      //手机号正则
      phonerule: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入手机号')
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
          return Promise.reject('手机号格式不正确!')
        } else {
          return Promise.resolve()
        }
      },
      //发送验证码验证手机号
      yzphone() {
        if (data.register.phone === '') {
          message.error('请输入手机号')
          return false
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(data.register.phone)) {
          message.error('手机号格式不正确')
          return false
        } else {
          return true
        }
      },
      //身份证号正则
      phoneNumberCheck: async (Rule, value) => {
        if (value === '') {
          return Promise.reject('请输入身份证号')
        } else if (
          !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            value
          )
        ) {
          return Promise.reject('身份证号格式不正确!')
        } else {
          return Promise.resolve()
        }
      },

      //点击弹框
      registershow() {
        if (store.state.HzType) {
          publicFn.routeJump({
            path: '/Registration',
          })
        } else {
          data.registertype = true
        }
      },
      //发送验证码
      sendPhoneCode() {
        if (data.isClick) {
          return false
        }
        //验证手机号
        if (!data.yzphone()) {
          return false
        }
        data.isClick = true
        let currentTime = data.currentTime
        http
          .post({
            serviceCode: 'sendPhoneCode',
            param: {
              phone: data.register.phone,
              type: 2,
            },
          })
          .then(() => {
            let timeInterval = setInterval(function () {
              currentTime-- //倒计时
              data.btnText = currentTime + ''
              if (currentTime <= 0) {
                clearInterval(timeInterval) //重置倒计时
                data.btnText = '发送验证码'
                data.currentTime = 60
                data.isClick = false
              }
            }, 1000)
            message.success('发送验证码成功')
          })
          .catch(() => {
            data.isClick = false
          })
      },
      //注册提交
      registeronFinish(val) {
        if(!val.qy.provinceCode){
          return message.warning('请选择所在区域省份')
        }
        if(!val.qy.cityCode){
          return message.warning('请选择所在区域市区')
        }
        if(!val.qy.countyCode){
          return message.warning('请选择所在区域县')
        }
        http.post({
            serviceCode: 'regUserInfo',
            param: {
              ...val,
              identityType: 1,
              province: val.qy.provinceCode,
              city: val.qy.cityCode,
              county: val.qy.countyCode,
            },
          })
          .then((res) => {
            localStorage.setItem('userinfo', JSON.stringify(res))
            store.commit('layou/setUserinfo', res)
            message.success(res.message)
            // 存储头像到本地
            store.commit('layou/setavatar', res.userInfo.avatar)
            data.getavatar = res.userInfo.avatar
            data.registertype = false
          })
      },
      formState: {
        username: null,
        password: null,
      },
      uuid: null,
      imgyzm: '',
      // 头像
      getavatar: store.state.layou.avatar,
      getyzm() {
        http
          .post({
            serviceCode: 'hzRandomImage',
            param: {},
          })
          .then((res) => {
            data.uuid = res.uuid
            data.imgyzm = res.result
          })
      },
      // 登录成功
      onFinish: (values) => {
        if (store.state.HzType) {
          http
            .post({
              serviceCode: 'hzUserLogin',
              param: {
                identityNo: values.username,
                pwd: values.password,
                captcha: values.captcha,
                uuid: data.uuid,
              },
            })
            .then((res) => {
              // 判断条件，为空弹二维码，成功关闭二维码
              if (res.code == 106 && (pages == 'hz' || pages == 'hz1')) {
                towCode.value = true
                picurl.value = res?.picurl
                message.error(res.message, 5)
              } else {
                towCode.value = false
                // 个人信息存储
                data.userinfo = res
                localStorage.setItem('userinfo', JSON.stringify(res))
                store.commit('layou/setUserinfo', res)
                message.success(res.message)
                // 存储头像到本地
                store.commit('layou/setavatar', res.userInfo.avatar)
                data.getavatar = res.userInfo.avatar
              }
            })
        } else {
          http
            .post({
              serviceCode: 'userLogin',
              param: {
                userName: values.username,
                pwd: values.password,
              },
            })
            .then((res) => {
              // 个人信息存储
              data.userinfo = res
              localStorage.setItem('userinfo', JSON.stringify(res))
              store.commit('layou/setUserinfo', res)
              message.success(res.message)
              // 存储头像到本地
              store.commit('layou/setavatar', res.userInfo.avatar)
              data.getavatar = res.userInfo.avatar
            })
        }
      },
      // 登录失败
      onFinishFailed: (errorInfo) => {
        console.log('Failed:', errorInfo)
      },
      // 去个人中心
      toZhongxin: () => {
        publicFn.routeJump({
          path: '/Personal',
        })
      },
      fgfwd: null,
      // 忘记密码
      forgot() {
        data.fgfwd.registershow()
      },
    })
    data.getyzm()
    // 监听获取信息
    watch(
      () => store.state.layou.userinfo,
      (newl) => {
        data.userinfo = newl
      }
    )
    // 监听头像变化
    watch(
      () => store.state.layou.avatar,
      (newl) => {
        data.getavatar = newl
      }
    )
    const towCode = ref(false)
    const picurl = ref('');
    onMounted(()=>{
      if(['yt','lc','ta'].some(item=>{return item===pages})){
        data.fnTechnicalJobculum();
      }
    })
    // 成功后
    return {
      ...toRefs(data),
      pages,
      hzShow,
      towCode,
      picurl,
    }
  },
}
</script>

<style scoped>
.input_box_li_zyx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.img_ban {
  width: 100%;
  display: block;
}
.mab15 {
  margin-bottom: 12px;
}
.boxtt {
  top: 26px;
  right: 20%;
  width: 100%;
  height: 100%;
  /* background: #fff; */
  background: rgba(245, 245, 245, 1);
  padding: 16px 26px 21px 26px;
}
.boxttb {
  padding: 39px 21px !important;
}
.box_t {
  width: 100%;
  height: 94%;
  box-sizing: border-box;
  /*box-shadow: 0px 6px 12px 0px #CCCCCC;*/
  border-radius: 10px !important;
  overflow: hidden;
}
.hzbox_t {
  position: relative;
  height: 100%;
}

.dengm {
  display: flex;
}

.san {
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #e2e4f3;
  border-right-color: #e2e4f3;
}

.denglu {
  background: #fff;
  flex: 1;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.zhuan {
  background: #e2e4f3;
}

.input_box {
  margin-top: 15px;
}

.input_li {
  width: 100%;
  margin-bottom: 10px;
  height: 40px !important;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 18px;
}

.box_t /deep/ .ant-input {
  background: #f6f6f6;
}

.input_ya {
  width: 50px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 5px;
}

.butt_l {
  cursor: pointer;
  background: #0059e8;
  border-radius: 4px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
  padding: 13px 0;
}

.span_ll {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  text-decoration: underline;
  color: #1168f3;
  margin-top: -12px;
  display: flex;
  justify-content: space-between;
}

.sp_s {
  cursor: pointer;
  color: #666;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.sp_l {
  cursor: pointer;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  text-decoration: underline;
}

.input_box_li {
  /* margin-bottom: 15px; */
}

.hydl {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
}

.input_box_yz {
  display: flex;
  justify-content: space-between;
}

.yz_img {
  background: #8df;
  width: 100px;
  height: 38px;
}

.primarybutt {
  width: 100%;
  background: #0059e8;
  border-radius: 4px;
}

.phoneCode {
  display: flex;
  justify-content: space-between;
}

.phoneCode_l {
  width: calc(100% - 100px);
}

.phoneCode_r {
  width: 100px;
  background: #40a9ff;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  height: 32px;
}
.b_cg_h {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
  margin-bottom: 40px;
  text-align: center;
}
.b_cg_img {
  width: 56px;
  height: 56px;
  background: #8df;
  border-radius: 50%;
  margin-right: 9px;
}
.b_cg_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.b_cg_name {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.b_cg_box_zx {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 37px;
}
.b_cg_zx {
  padding: 6px 12px;

  background: #f1822e;
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}
.b_cg_ewm {
  width: 110px;
  height: 110px;
  background: #8df;
  border-radius: 50%;
  margin-bottom: 12px;
}
.b_cg_ewm_box {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  font-size: 16px;
  text-align: center;
}
.b_cg_da_box {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}
.fg_pwd_buttonbox {
  text-align: right;
}
.fg_pwd_btn_l {
  width: 100px;
}
.towCode {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 150px;
  height: 150px;
  background-color: #ffffff;
}
.picurlStyle {
  width: 150px;
  height: 150px;
}
</style>
